import "./App.css";
import "./styles/font.css";
import "./styles/percent.css";
import "./styles/position.css";
import "./styles/padding.css";
import "./styles/min-height.css";
import "./styles/max-width.css";
import "./styles/max-width.css";
import "./styles/button.css";
import "./styles/loader.css";
import "./styles/width.css";
import "./styles/gradient.css";
import "./styles/scrollbar.css";
import "./styles/ProgressBar.css";
import AppRouter from "./router/AppRouter";

function App() {
  return <AppRouter />;
}

export default App;
