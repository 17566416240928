import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "../declarations/productService";

interface HeaderState {
  products: ProductType[] | null;
  shop: ProductType[] | null;
  product: ProductType | null;
}

const initialState: HeaderState = {
  products: null,
  shop: null,
  product: null,
};

export const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<ProductType[] | null>) => {
      state.products = action.payload;
    },
    setShop: (state, action: PayloadAction<ProductType[] | null>) => {
      state.shop = action.payload;
    },
    setProduct: (state, action: PayloadAction<ProductType | null>) => {
      state.product = action.payload;
    },
  },
});

export const { setProducts, setShop, setProduct } = product.actions;

export default product.reducer;
