import { useDispatch, useSelector } from "react-redux";
import {
  GetOrdersProps,
  OrderType,
  UpdateOrderProps,
  checkoutFunctionProps,
} from "../declarations/orderServices";
import {
  setProgressBarActive,
  setThankYouModal,
} from "../reducers/pageReducer";
import { Checkout, GetOrders, UpdateOrder } from "../api/services/order";
import { RootState } from "../app/store";
import CachedItem, { CacheItem } from "../config/cache";
import { setCart, setOrders } from "../reducers/orderReducer";
import { setRecentOrders } from "../reducers/statsReducer";
import { OrderStatus } from "../config/status";
import { Sort } from "../config/api";

export default function useOrder() {
  const dispatch = useDispatch();
  const { cart, orders } = useSelector((state: RootState) => state.order);
  const { recentOrders } = useSelector((state: RootState) => state.stats);

  const checkout = async ({
    products,
    deliveryAddress,
    phoneNumber,
  }: checkoutFunctionProps) => {
    let orderProducts = products.map((product) => product.product);

    dispatch(setProgressBarActive(true));
    const response = await Checkout({
      order: {
        products: orderProducts,
        deliveryAddress,
        phoneNumber,
      },
    });
    const { status, data, message } = response;
    if (status === 201) {
      CacheItem(CachedItem.phone, phoneNumber);
      dispatch(setThankYouModal(true));
      let productIndexes = products.map((product) => product.index);

      const updatedCart = cart.filter(
        (_, index) => !productIndexes.includes(index)
      );
      dispatch(setCart(updatedCart));
    }
    dispatch(setProgressBarActive(false));
  };

  const getRecentOrders = async (reload?: boolean) => {
    if (recentOrders && recentOrders.length > 0 && !reload) return;
    dispatch(setProgressBarActive(true));
    const response = await GetOrders({
      status: OrderStatus.ordered,
      sort: Sort.descendingCreatedAtOrder,
    });
    const { orders, status, message } = response;
    if (status === 200) {
      orders && dispatch(setRecentOrders(orders));
    }
    dispatch(setProgressBarActive(false));
  };

  const getOrdersWithDispatch = async ({
    category,
    limit,
    offset,
    populate,
    search,
    setLoading,
    sort,
    status,
    type,
  }: GetOrdersProps) => {
    if (orders && orders.length > 0) return;
    dispatch(setProgressBarActive(true));
    const response = await GetOrders({
      category,
      limit,
      offset,
      populate,
      search,
      setLoading,
      sort: Sort.descendingCreatedAtOrder,
      status,
      type,
    });
    const {
      orders: responseOrders,
      status: responseStatus,
      message,
    } = response;
    if (responseStatus === 200) {
      responseOrders && dispatch(setOrders(responseOrders));
    }
    dispatch(setProgressBarActive(false));
  };

  const getOrders = async ({
    category,
    limit,
    offset,
    populate,
    search,
    setLoading,
    sort,
    status,
    type,
  }: GetOrdersProps) => {
    const response = await GetOrders({
      category,
      limit,
      offset,
      populate,
      search,
      setLoading,
      sort: sort || Sort.descendingCreatedAtOrder,
      status,
      type,
    });
    const { orders, status: responseStatus, message } = response;
    if (responseStatus === 200) {
      return orders;
    }
  };

  const updateOrder = async ({
    id,
    address,
    setLoading,
    status,
  }: UpdateOrderProps) => {
    const response = await UpdateOrder({
      id,
      address,
      setLoading,
      status,
    });
    const { order, status: responseStatus, message } = response;
    return responseStatus;
  };

  return {
    checkout,
    getRecentOrders,
    getOrders,
    getOrdersWithDispatch,
    updateOrder,
  };
}
