import React from "react";
import Plus from "../../assets/PlusIcon2.svg";
import { AddProductProps } from "../../declarations/CommonComponents";

function AddProductImages({
  image1,
  image2,
  image3,
  image4,
  setImage1,
  setImage2,
  setImage3,
  setImage4,
}: AddProductProps) {
  return (
    <div className={ClassName.imagesContainer}>
      <div className={ClassName.imageContainer}>
        <label htmlFor="image1">
          {image1 ? (
            <img
              src={URL.createObjectURL(image1)}
              alt="product"
              className={ClassName.image}
            />
          ) : (
            <img src={Plus} alt="add image" className={ClassName.plusIcon} />
          )}
        </label>
        <input
          type="file"
          id="image1"
          accept="image/*"
          onChange={(e) => e.target.files?.[0] && setImage1(e.target.files[0])}
        />
      </div>
      <div className={ClassName.imageContainer}>
        <label htmlFor="image2">
          {image2 ? (
            <img
              src={URL.createObjectURL(image2)}
              alt="product"
              className={ClassName.image}
            />
          ) : (
            <img src={Plus} alt="add image" className={ClassName.plusIcon} />
          )}
        </label>
        <input
          type="file"
          id="image2"
          accept="image/*"
          onChange={(e) => e.target.files?.[0] && setImage2(e.target.files[0])}
        />
      </div>
      <div className={ClassName.imageContainer}>
        <label htmlFor="image3">
          {image3 ? (
            <img
              src={URL.createObjectURL(image3)}
              alt="product"
              className={ClassName.image}
            />
          ) : (
            <img src={Plus} alt="add image" className={ClassName.plusIcon} />
          )}
        </label>
        <input
          type="file"
          id="image3"
          accept="image/*"
          onChange={(e) => e.target.files?.[0] && setImage3(e.target.files[0])}
        />
      </div>
      <div className={ClassName.imageContainer}>
        <label htmlFor="image4">
          {image4 ? (
            <img
              src={URL.createObjectURL(image4)}
              alt="product"
              className={ClassName.image}
            />
          ) : (
            <img src={Plus} alt="add image" className={ClassName.plusIcon} />
          )}
        </label>
        <input
          type="file"
          id="image4"
          accept="image/*"
          onChange={(e) => e.target.files?.[0] && setImage4(e.target.files[0])}
        />
      </div>
    </div>
  );
}

export default AddProductImages;

const ClassName = {
  imagesContainer:
    "bg-white rounded-xl p-4 flex flex-row flex-wrap items-center justify-between",
  plusIcon: "h-11 w-11 object-contain cursor-pointer",
  imageContainer:
    "bg-dbackground h-24 w-24 flex items-center justify-center cursor-pointer mb-2 lg:mb-0",
  image: "h-24 w-24 object-contain cursor-pointer",
};
