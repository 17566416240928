import { useState } from "react";
import Section from "../components/common/Section";
import FaqQuestion from "../components/home/FaqQuestion";
import Faqs from "../constants/faq";
import AppCopy from "../copy/AppCopy";
import { FaqInterface } from "../declarations/homeComponents";

function HomeFAQSection() {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const { faqTitleText } = AppCopy.home;
  return (
    <div className={ClassName.container}>
      <Section classname={ClassName.content}>
        <div className={ClassName.contentContainer}>
          <div className={ClassName.faqTitle}>{faqTitleText}</div>
          {Faqs.map((faq: FaqInterface, index: number) => (
            <FaqQuestion
              key={index}
              faq={faq}
              selected={selected === index}
              onClick={() => handleSelect(index)}
            />
          ))}
        </div>
      </Section>
    </div>
  );
}

export default HomeFAQSection;

const ClassName = {
  container:
    "w-full min-height-40vh bg-tertiary flex items-center justify-center font-secondary",
  content: "flex-row w-full",
  contentContainer: "w-90percent md:w-8/12 flex flex-col items-center py-20 ",
  faqTitle: "text-3xl md:text-5xl uppercase font-primary text-black mb-10",
  contentRight:
    "flex flex-col h-full items-start justify-center pl-20 text-xl flex-1 text-quaternary pb-10 pt-52",
};
