import { ThankyouModalProps } from "../../declarations/CommonComponents";
import Modal from "react-modal";
import AppCopy from "../../copy/AppCopy";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../router/Paths";
import { useDispatch, useSelector } from "react-redux";
import { setThankYouModal } from "../../reducers/pageReducer";
import { RootState } from "../../app/store";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "none",
  },
};

function ThankyouModal({ modalOpen, setModalOpen }: ThankyouModalProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { thankYouModal } = useSelector((state: RootState) => state.page);

  const OnCloseModal = () => {
    setModalOpen && setModalOpen(false);
    dispatch(setThankYouModal(false));
    navigate(Paths.home);
  };

  const { closeButtonText, subtitleText, titleText } =
    AppCopy.modal.thankyouModal;
  return (
    <Modal
      isOpen={thankYouModal}
      onRequestClose={OnCloseModal}
      style={customStyles}
      contentLabel="Thank you"
      shouldCloseOnOverlayClick={false}
    >
      <div className={ClassName.container}>
        <div className={ClassName.title}>{titleText}</div>
        <div className={ClassName.subtitle}>{subtitleText}</div>
        <button className={ClassName.closeButton} onClick={OnCloseModal}>
          {closeButtonText}
        </button>
      </div>
    </Modal>
  );
}

export default ThankyouModal;

const ClassName = {
  container:
    "w-90vw sm:w-650px h-96 bg-tertiary flex flex-col items-center justify-center font-secondary",
  title:
    "w-80percent sm:w-2/3 text-center items-center font-primary text-3xl sm:text-5xl mb-4",
  subtitle: "w-2/3 text-center text-base sm:text-xl mb-4",
  closeButton: "button-primary bg-primary text-white",
};
