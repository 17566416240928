import React, { useEffect, useState } from "react";
import { CommonStyles } from "../styles/CommonStyles";
import AppCopy from "../copy/AppCopy";
import DashboardHeader from "../components/common/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setActiveTab } from "../reducers/pageReducer";
import { SideBarTabs } from "../constants/tabs";
import Plus from "../assets/PlusIcon2.svg";
import AddProductImages from "../components/shop/AddProductImages";
import NameContainerForm from "../components/shop/NameContainerForm";
import AvailableSizesForm from "../components/shop/AvailableSizesForm";
import StepsForm from "../components/shop/StepsForm";
import { useGetProducts } from "../hook/useGetProducts";
import { useAdminNavigate } from "../hook/useAdminNavigate";
import { useNavigate } from "react-router-dom";

function DashboardAddProductPage() {
  const dispatch = useDispatch();
  const adminNavigate = useAdminNavigate();
  const navigate = useNavigate();
  const { createProduct } = useGetProducts();
  const { products } = useSelector((state: RootState) => state.product);

  const {
    title,
    addProductButtonText,
    categoryErrorText,
    imagesErrorText,
    inStockErrorText,
    priceErrorText,
    productNameErrorText,
    typeErrorText,
  } = AppCopy.dashboardAddProduct;

  //states
  const [image1, setImage1] = useState<File>();
  const [image2, setImage2] = useState<File>();
  const [image3, setImage3] = useState<File>();
  const [image4, setImage4] = useState<File>();
  const [name, setName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [amountInStock, setAmountInStock] = useState<number>(0);
  const [type, setType] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [sizesAvailable, setSizesAvailable] = useState<boolean>(false);
  const [stepsAvailable, setStepsAvailable] = useState<boolean>(false);
  const [availableSizes, setAvailableSizes] = useState<
    { name: string; price: number }[]
  >([]);
  const [stepPre, setStepPre] = useState<string>("");
  const [stepPost, setStepPost] = useState<string>("");
  const [steps, setSteps] = useState<string[]>([]);

  useEffect(() => {
    dispatch(setActiveTab(SideBarTabs.Products.name));
  }, [setActiveTab]);

  const add = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price.toString());
    formData.append("stock", amountInStock.toString());
    formData.append("howToUsePre", stepPre);
    formData.append("howToUsePost", stepPost);
    availableSizes.forEach((size, index) => {
      formData.append(`sizes[${index}][sizeName]`, size.name);
      formData.append(`sizes[${index}][price]`, size.price.toString());
    });
    formData.append("availableSizes", sizesAvailable.toString());
    formData.append("type", type);
    formData.append("category", category);
    steps.forEach((step, index) => {
      formData.append(`howToUseSteps[${index}]`, step);
    });
    if (image1) {
      formData.append("images", image1);
    }
    if (image2) {
      formData.append("images", image2);
    }
    if (image3) {
      formData.append("images", image3);
    }
    if (image4) {
      formData.append("images", image4);
    }
    const status = await createProduct({ payload: formData });
    if (status === 201) {
      navigate(-1);
    }
  };

  const handleAdd = () => {
    if (!name) {
      alert(productNameErrorText);
      return;
    }
    if (!price) {
      alert(priceErrorText);
      return;
    }
    if (!amountInStock) {
      alert(inStockErrorText);
      return;
    }
    if (!type) {
      alert(typeErrorText);
      return;
    }
    if (!category) {
      alert(categoryErrorText);
      return;
    }
    if (!image1) {
      alert(imagesErrorText);
      return;
    }
    if (!image2) {
      alert(imagesErrorText);
      return;
    }
    if (!image3) {
      alert(imagesErrorText);
      return;
    }
    if (!image4) {
      alert(imagesErrorText);
      return;
    }
    add();
  };

  return (
    <div
      className={`${CommonStyles.dashBoardPageWrapper} ${ClassName.container}`}
    >
      <DashboardHeader pageName={title} />
      <div className={ClassName.content}>
        <div className={ClassName.leftForm}>
          <AddProductImages
            image1={image1}
            image2={image2}
            image3={image3}
            image4={image4}
            setImage1={setImage1}
            setImage2={setImage2}
            setImage3={setImage3}
            setImage4={setImage4}
          />

          <NameContainerForm
            name={name}
            setName={setName}
            price={price}
            setPrice={setPrice}
            setAmountInStock={setAmountInStock}
            amountInStock={amountInStock}
            type={type}
            setType={setType}
            category={category}
            setCategory={setCategory}
            description={description}
            setDescription={setDescription}
          />

          <button className={ClassName.mainButton} onClick={handleAdd}>
            {addProductButtonText}
          </button>
        </div>
        <div className={ClassName.rightForm}>
          <AvailableSizesForm
            sizesAvailable={sizesAvailable}
            setSizesAvailable={setSizesAvailable}
            availableSizes={availableSizes}
            setAvailableSizes={setAvailableSizes}
          />

          <StepsForm
            availableSteps={stepsAvailable}
            postSteps={stepPost}
            preSteps={stepPre}
            setPostSteps={setStepPost}
            setPreSteps={setStepPre}
            setSteps={setSteps}
            setStepsAvailable={setStepsAvailable}
            steps={steps}
          />
        </div>
        <button className={ClassName.mobileButton} onClick={handleAdd}>
          {addProductButtonText}
        </button>
      </div>
    </div>
  );
}

export default DashboardAddProductPage;

const ClassName = {
  container: "",
  content:
    "flex flex-col md:flex-row flex-1  gap-4 mt-4 text-secondaryText text-xs md:text-sm",
  leftForm: "flex flex-col gap-2 flex-1",
  mainButton:
    "bg-dprimary h-12 items-center justify-center rounded-lg text-white text-base font-semibold hidden md:flex",
  mobileButton:
    "bg-dprimary h-12 items-center justify-center rounded-lg text-white text-sm md:text-base font-semibold flex md:hidden",
  rightForm: "flex flex-col flex-1 gap-4",
};
