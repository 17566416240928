const CachedItem = {
  cart: "cart",
  phone: "phone",
  token: "token",
  user: "user",
};

export const SessionCacheItem = {
  product: "product",
  adminPath: "adminPath",
};

export const CacheItem = (key: string, data: any, isString?: boolean) => {
  let dataToBeCached = data;
  if (!isString) {
    dataToBeCached = JSON.stringify(dataToBeCached);
  }
  localStorage.setItem(key, dataToBeCached);
};

export const LoadCachedItem = (key: string, isString?: boolean): any => {
  let data = localStorage.getItem(key);
  if (!data) return "";
  if (!isString) {
    data = JSON.parse(data);
  }
  return data;
};

export const CacheItemSession = (
  key: string,
  data: any,
  isString?: boolean
) => {
  let dataToBeCached = data;
  if (!isString) {
    dataToBeCached = JSON.stringify(dataToBeCached);
  }
  sessionStorage.setItem(key, dataToBeCached);
};

export const LoadCachedItemSession = (key: string, isString?: boolean): any => {
  let data = sessionStorage.getItem(key);
  if (!data) return "";
  if (!isString) {
    data = JSON.parse(data);
  }
  return data;
};

export default CachedItem;
