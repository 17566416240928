import React, { useEffect } from "react";
import { StepsFormProps } from "../../declarations/CommonComponents";
import AppCopy from "../../copy/AppCopy";
import Plus from "../../assets/PlusIcon.svg";

function StepsForm({
  availableSteps,
  postSteps,
  preSteps,
  setPostSteps,
  setPreSteps,
  setSteps,
  setStepsAvailable,
  steps,
}: StepsFormProps) {
  const { howToUsePostText, howToUsePreText, stepsToUse, stepsText } =
    AppCopy.dashboardAddProduct;

  const CheckSteps = () => {
    if (!steps) return;
    const newSteps = [...steps];
    let emptySteps = 0;
    for (let i = 0; i < newSteps.length; i++) {
      if (newSteps[i] === "") {
        emptySteps++;
        if (emptySteps > 1) {
          newSteps.splice(i, 1);
          i--;
        }
      }
    }
    if (steps.length === newSteps.length) return;
    setSteps(newSteps);
  };

  useEffect(() => {
    CheckSteps();
  }, [steps]);

  const onCheckboxClick = () => {
    setStepsAvailable(!availableSteps);
    if (!availableSteps) {
      setSteps([""]);
    } else {
      setSteps([]);
    }
  };

  const handleAdd = () => {
    setSteps([...steps, ""]);
  };

  return (
    <div className={ClassName.container}>
      <div className={ClassName.titleContainer}>
        <div>{stepsText}</div>
        <div className={ClassName.checkbox} onClick={onCheckboxClick}>
          {availableSteps && <div className={ClassName.checkBoxFilled}></div>}
        </div>
      </div>
      <div className={ClassName.contentContainer}>
        <div className={ClassName.content}>
          <label htmlFor="howToUsePreText">{howToUsePreText}</label>
          <textarea
            id="howToUsePreText"
            name="howToUsePreText"
            className={ClassName.textArea}
            value={preSteps}
            onChange={(e) => setPreSteps(e.target.value)}
          />
          <label htmlFor="howToUsePostText">{howToUsePostText}</label>
          <textarea
            id="howToUsePostText"
            name="howToUsePostText"
            className={ClassName.textArea}
            value={postSteps}
            onChange={(e) => setPostSteps(e.target.value)}
          />
          <div>{stepsToUse}</div>
          {steps &&
            steps?.map((step, index) => (
              <input
                key={index}
                type="text"
                placeholder={`Step ${index + 1}`}
                className={` ${ClassName.input}`}
                value={step}
                onChange={(event) => {
                  const newSteps = [...steps];
                  newSteps[index] = event.target.value;
                  setSteps(newSteps);
                }}
              />
            ))}
        </div>
      </div>
      <div className={ClassName.plusContainer} onClick={handleAdd}>
        <img src={Plus} alt="" className={ClassName.plus} onClick={handleAdd} />
      </div>
    </div>
  );
}

export default StepsForm;

const ClassName = {
  container: "flex flex-col h-fit md:h-50vh rounded-xl bg-white px-4",
  titleContainer:
    "flex flex-row w-full py-1 pt-2 mb-2 border-b border-primaryBorder items-center justify-between font-semibold text-secondaryText",
  checkbox:
    "cursor-pointer border  border-dprimary h-3 w-3 flex items-center justify-center",
  checkBoxFilled: "h-2 w-2 bg-dprimary",
  plusContainer: "w-full flex flex-row items-center justify-end pb-4",
  plus: "cursor-pointer w-3 h-3 object-contain",
  contentContainer: "flex flex-1 flex-col overflow-scroll scrollbar-card pr-4",
  content: "flex flex-col flex-1",
  textArea:
    "w-full h-16 mb-2 mt-1 border border-borderPrimary rounded-md px-4 py-2 mb-2",
  input: "w-full h-8 mb-2 mt-1 border border-borderPrimary rounded-md px-4",
};
