const AppCopy = {
  home: {
    heroDeliveryStatusText: "Free Delivery",
    heroTitleText: "Elegance Meets Quality",
    heroSubtitleText:
      "Unlock Your True Potential with Products That Deliver Effective Results.",
    heroShopButtonText: "Shop",
    aboutUsSectionText:
      "We're passionate about delivering products that embody beauty, effectiveness, and lasting value.",
    faqTitleText: "Frequently asked questions",
  },
  shop: {
    heroTitleText: "Get the Finest Products Delivered to You",
    heroSubmitButtonText: "Search",
    allProductsTitleText: "All products",
    addToCartButtonText: "Add to cart",
    loadMoreButtonText: "Load More",
  },
  product: {
    selectSizeTitleText: "Selected size",
    addToCartButtonText: "Add to cart",
    freeDeliveryTagText: "Free Delivery",
    descriptionTitleText: "Description",
    howToTitleText: "How to use",
  },
  cart: {
    cartPageTitleText: "My Cart",
    chooseProductsTitleText: "Choose all products",
    sizeText: "Size/Quantity ",
    orderSummaryText: "Order summary",
    orderTotalText: "Order Total",
    deliverAddressInputTitleText: "Delivery address",
    phoneNumberInputTitleText: "Phone number",
    phoneNumberPlaceholder: "xxxxxxxxxx",
    checkoutButtonText: "Checkout",
    emptyCartText: "No items in your cart",
    zeroSelectedItemsErrorText: "There are no selected items",
    noDeliveryAddressErrorText: "Enter your delivery address",
    noPhoneNumberErrorText: "Enter your phone number",
    invalidPhoneErrorText: "Phone number has to be 10 characters",
  },
  modal: {
    thankyouModal: {
      titleText: "Thank you for your order",
      subtitleText: "We will call you shortly to confirm your order",
      closeButtonText: "Close",
    },
    orderModal: {
      deliveryAddressText: "Delivery address",
      statusText: "Status",
      deliveryManText: "Delivery man",
      totalPriceText: "Total price",
      updateButtonText: "Update",
      productNameText: "Product name",
      quantityText: "Quantity",
      selectedSizeText: "Selected size",
      priceText: "Price",
    },
  },
  footer: {
    logoDescription: "Where Trust Meets Excellence: Discover Effective Quality",
    aboutTab: "About",
    shopTab: "Shop",
    contactUsTab: "Contact Us",
    aboutHomeTab: "Home",
    aboutProductsTab: "Products",
    aboutFaqsTab: "FAQs",
  },
  contact: {
    title: "Send us a message",
    namePlaceholder: "Full name",
    emailPlaceholder: "Email or Phone Number",
    messagePlaceholder: "Message",
    submitButtonText: "Submit",
  },
  loginForm: {
    loginButton: "Login",
    userNamePlaceholder: "Username",
    passwordPlaceholder: "Password",
    formTitle: "Admin Panel",
    usernameErrorText: "Username is required",
    passwordErrorText: "Password is required",
  },
  sideBar: {
    titleText: "DASHBOARD",
    logoutText: "Logout",
  },
  dashboardOverview: {
    title: "Overview",
    top5Products: "Top 5 Products",
    recentOrders: "Recent Orders",
  },
  stats: {
    totalRevenueText: "Total Revenue",
    totalExpensesText: "Total Expenses",
    totalProductsText: "Total Products",
    totalDeliveryPeopleText: "Total Delivery People",
    totalOrdersText: "Total Orders",
    totalCancelledText: "Total Cancelled",
    totalDeliveredText: "Total Delivered",
    totalAssignedText: "Total Assigned",
    totalPendingText: "Total Pending",
    totalMessagesText: "Total Messages",
  },
  orders: {
    customer: "Customer",
    products: "Products",
    deliveryMan: "Deliveryman",
    status: "Status",
    orderDate: "Order date",
  },
  dashboardOrders: {
    title: "Orders",
  },
  dashboardProducts: {
    title: "Products",
    searchButtonText: "Search",
    searchPlaceholder: "Search products ....",
  },
  dashboardAddProduct: {
    title: "Add Product",
    productName: "Product Name",
    priceText: "Price",
    inStockText: "In Stock",
    typeText: "Type",
    categoryText: "Category",
    descriptionText: "Description",
    availableSizesText: "Available sizes",
    nameText: "Name",
    stepsText: "Steps",
    howToUsePreText: "How to use pre text",
    howToUsePostText: "How to use post text",
    stepsToUse: "Steps to use",
    addProductButtonText: "Add Product",
    productNameErrorText: "Product name is required",
    priceErrorText: "Price is required",
    categoryErrorText: "Category is required",
    typeErrorText: "Type is required",
    inStockErrorText: "In stock is required",
    imagesErrorText: "4 images are required",
  },
  dashboardUpdateProduct: {
    titleText: "Update Product",
    updateButtonText: "Update Product",
  },
  dashboardProduct: {
    name: "Product Name",
    inStock: "In Stock",
    price: "Price",
    type: "Type",
    category: "Category",
    availableSizes: "Available sizes",
    howToUsePreText: "How to use pre text",
    howToUsePostText: "How to use post text",
    stepsToUse: "Steps to use",
    description: "Description",
    createdAt: "Created at",
  },
};

export default AppCopy;
