const Faqs = [
  {
    question: "How do orders and deliveries work?",
    answer:
      "When you place an order on our website, we will call you to confirm details. Once payment is confirmed, your order will be processed and shipped. We offer free delivery.",
  },
  {
    question: "How can I be sure of the quality?",
    answer:
      "We carefully select our products from reputable brands and suppliers to ensure high quality.",
  },
  {
    question: "How do I browse products and add them to my cart??",
    answer:
      'You can browse our products on the website homepage. To add items to your cart, click the "Add to Cart" button. You can also click directly on a product image to view details and size options before adding to your cart.',
  },
  {
    question: "What information do I provide when checking out?",
    answer:
      "When you click checkout from your cart page, you will need to enter your phone number and delivery address where our agent can find you for cash on delivery. This allows us to process your order and schedule delivery.",
  },
  {
    question: "What payment methods can I use?",
    answer:
      "We currently only accept payment by cash on delivery. When you place your order, our agent will call you to arrange the delivery details. Payment must be made in cash to the delivery agent upon receiving your order. Online payments are not available at this time.",
  },
];

export default Faqs;
