import { useNavigate } from "react-router-dom";
import {
  CategoryTabInterface,
  CategoryTabs,
  CategoryTabsType,
} from "../constants/tabs";
import { Paths } from "../router/Paths";
import { useGetProducts } from "../hook/useGetProducts";
import { useDispatch } from "react-redux";
import { setProgressBarActive } from "../reducers/pageReducer";
import { setProducts } from "../reducers/productReducer";

function HomeCategorySection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getProducts } = useGetProducts();

  const handleSelect = async (category: CategoryTabsType) => {
    dispatch(setProgressBarActive(true));
    const response = await getProducts({ category });
    dispatch(setProducts(response));
    dispatch(setProgressBarActive(false));
    navigate(`${Paths.shop}/${category}`);
  };
  return (
    <div className={ClassName.container}>
      {Object.values(CategoryTabs).map(
        (category: CategoryTabInterface, index: number) => (
          <div
            className={ClassName.category}
            onClick={() => handleSelect(category.name)}
          >
            <div className={ClassName.catagoryOverlay}></div>
            <div className={ClassName.categoryContent}>
              {category.displayName}
            </div>
            <img
              src={category.image}
              alt={`${category.name} category`}
              className={ClassName.categoryImage}
            />
          </div>
        )
      )}
    </div>
  );
}

export default HomeCategorySection;

const ClassName = {
  container: "w-full h-fit flex flex-wrap",
  category:
    "w-full md:w-1/2 md:h-60vh flex items-center justify-center object-cover cursor-pointer group relative",
  categoryImage: "w-full h-full object-cover",
  catagoryOverlay:
    "absolute h-full bg-black w-full opacity-40 sm:opacity-0 group-hover:opacity-40 transition-all",
  categoryContent:
    "absolute w-full h-full opacity-100 px-4 md:px-0 sm:opacity-0 group-hover:opacity-100 font-primary flex items-center justify-center text-center text-tertiary text-5xl md:text-8xl uppercase transition-all",
};
