import React from "react";
import { DashboardProductProps } from "../../declarations/CommonComponents";
import Chevron from "../../assets/ChevronDown.svg";
import Edit from "../../assets/EditIcon.svg";
import Delete from "../../assets/DeleteIcon.svg";
import AppCopy from "../../copy/AppCopy";
import { getPriceWithCurrency } from "../../functions/currency";
import { Capitalize } from "../../functions/text";
import { getCreatedAt } from "../../functions/time";

function DashboardProduct({
  product,
  selected,
  onSelect,
  onDelete,
  onEdit,
}: DashboardProductProps) {
  const {
    availableSizes,
    category,
    description,
    howToUsePostText,
    howToUsePreText,
    inStock,
    name,
    price,
    stepsToUse,
    type,
    createdAt,
  } = AppCopy.dashboardProduct;
  const handleSelect = () => {
    if (!selected) {
      onSelect();
    }
  };

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete ${product.name}`)) {
      onDelete(product);
    }
  };

  const handleEdit = () => {
    onEdit(product);
  };

  return (
    <div
      className={`${ClassName.container} ${!selected && ClassName.notSelected}`}
      onClick={handleSelect}
    >
      <div className={ClassName.containerContainer}>
        <div className={ClassName.content}>
          {!selected ? (
            <div className={ClassName.imageContainer}>
              {product.images && (
                <img
                  src={product.images[0]}
                  alt=""
                  className={ClassName.image}
                />
              )}
            </div>
          ) : (
            <div className={ClassName.imagesContainer}>
              {product.images &&
                product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt=""
                    className={ClassName.image2}
                  />
                ))}
            </div>
          )}
          <div className={ClassName.gridContainer}>
            <div className={ClassName.column1}>
              <span className={ClassName.title}>{name}</span>
              <span className={ClassName.mainText}>{product.name}</span>

              <span className={ClassName.title}>{inStock}</span>
              <span className={ClassName.mainText}>{product.stock}</span>

              <span className={ClassName.title}>{price}</span>
              <span className={ClassName.mainText}>
                {getPriceWithCurrency(product.price)}
              </span>

              {selected && (
                <>
                  <span className={ClassName.title}>{type}</span>
                  <span className={ClassName.mainText}>
                    {Capitalize(product?.type)}
                  </span>
                </>
              )}
            </div>
            <div className={ClassName.column2}>
              <span className={ClassName.title}>{category}</span>
              <span className={ClassName.mainText}>
                {Capitalize(product.category)}
              </span>

              <span className={ClassName.title}>{availableSizes}</span>
              {selected ? (
                <>
                  {product.sizes &&
                    product.sizes.length > 0 &&
                    product.sizes.map((size, index) => (
                      <span
                        className={`${
                          product.sizes &&
                          product.sizes.length > 0 &&
                          index === product.sizes?.length - 1
                            ? ClassName.mainText
                            : ClassName.sizes
                        }`}
                      >
                        {size.sizeName} : {getPriceWithCurrency(size.price)}
                      </span>
                    ))}
                </>
              ) : (
                <span className={ClassName.mainText}>
                  {product.availableSizes === true ? "true" : "false"}
                </span>
              )}

              <span className={ClassName.title}>{createdAt}</span>
              <span className={ClassName.mainText}>
                {product?.createdAt && getCreatedAt(product?.createdAt)}
              </span>
            </div>
            <div className={ClassName.column3}>
              {!selected && (
                <>
                  <span className={ClassName.title}>{type}</span>
                  <span className={ClassName.mainText}>
                    {Capitalize(product?.type)}
                  </span>
                </>
              )}

              {selected && (
                <>
                  <span className={ClassName.title}>{howToUsePreText}</span>
                  <span className={ClassName.mainText}>
                    {product?.howToUsePre}
                  </span>

                  <span className={ClassName.title}>{howToUsePostText}</span>
                  <span className={ClassName.mainText}>
                    {product?.howToUsePost}
                  </span>

                  <span className={ClassName.title}>{stepsToUse}</span>
                  {product.howToUseSteps &&
                    product.howToUseSteps.length > 0 &&
                    product.howToUseSteps.map((step, index) => (
                      <span
                        className={`${
                          product.howToUseSteps &&
                          product.howToUseSteps.length > 0 &&
                          index === product.howToUseSteps?.length - 1
                            ? ClassName.mainText
                            : ClassName.sizes
                        }`}
                      >
                        {index + 1}. {step}
                      </span>
                    ))}
                </>
              )}

              <span className={ClassName.title}>{description}</span>
              {selected ? (
                <span
                  className={ClassName.mainText}
                  dangerouslySetInnerHTML={{
                    __html: `${product?.description as string}`,
                  }}
                ></span>
              ) : (
                <span
                  className={ClassName.mainText}
                  dangerouslySetInnerHTML={{
                    __html: `${product?.description?.slice(0, 300) as string} ${
                      product?.description.length &&
                      product?.description.length > 300 &&
                      "..."
                    }`,
                  }}
                ></span>
              )}
            </div>
          </div>
        </div>
        {selected && (
          <div className={ClassName.buttonsContainer}>
            <img
              src={Edit}
              alt="edit"
              className={ClassName.button}
              onClick={handleEdit}
            />
            <img
              src={Delete}
              alt="delete"
              className={ClassName.button}
              onClick={handleDelete}
            />
          </div>
        )}
      </div>

      {!selected && (
        <div className={ClassName.loadMore}>
          <img src={Chevron} alt="" className={ClassName.loadMoreIcon} />
        </div>
      )}
    </div>
  );
}

export default DashboardProduct;

const ClassName = {
  container: "w-full bg-white rounded-xl p-2 md:p-4 flex flex-col mb-3",
  notSelected: "cursor-pointer",
  containerContainer: "w-full flex flex-row mb-2",
  content: "flex flex-col md:flex-row flex-1",
  imageContainer: "",
  imagesContainer:
    "grid grid-cols-2 grid-rows-2 gap-2 md:gap-4 mb-2 h-72 md:h-60",
  image: "h-32 w-full md:w-32 mb-2 object-contain shrink-0 bg-tertiary",
  image2: "h-32 md:h-28 w-35vw md:w-28 object-contain bg-tertiary",

  gridContainer: "gap-4 w-full flex flex-wrap md:flex-row flex-1 mr-2 md:mx-4",
  column1: "flex flex-col w-fit md:w-20percent",
  column2: "flex flex-col w-fit md:w-20percent",
  column3: "flex flex-col",

  loadMore:
    "w-full py-1 flex flex-row items-center justify-center cursor-pointer",
  loadMoreIcon: "w-3 object-contain",

  title: "text-xs md:text-sm text-secondaryText",
  mainText: "text-xs md:text-sm text-dprimary font-semibold mb-3",
  sizes: "text-xs md:text-sm text-dprimary font-semibold",

  buttonsContainer:
    "flex flex-col md:h-full shrink-0 items-center justify-between",
  button: "cursor-pointer h-5 contain",
};
