const Images = {
  delivery: require("../assets/Bike.png"),
  heroImage: require("../assets/HeroImage.png"),
  categoryMakeup: require("../assets/Makeup.png"),
  categoryHair: require("../assets/Baby-product.png"),
  categoryMen: require("../assets/Men.jpg"),
  categoryWomen: require("../assets/Women.png"),
  categoryJewelry: require("../assets/Watch.png"),
  categorySkincare: require("../assets/Skincare.png"),
  categoryBabyProducts: require("../assets/Baby-Products.jpg"),
  contactImage: require("../assets/ContactImage.png"),
  contactPhoneIcon: require("../assets/PhoneIcon.png"),
  contactEmailIcon: require("../assets/EmailIcon.png"),
  shopHeroImage: require("../assets/Products.png"),
  shopProduct: require("../assets/Product.png"),
};

export default Images;
