import React, { useState } from "react";
import Images from "../Images/Images";
import Section from "../components/common/Section";
import AppCopy from "../copy/AppCopy";
import Contact from "../constants/contact";
import { useMessages } from "../hook/useMessages";

function ContactSection() {
  const { sendMessage } = useMessages();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const OnSubmit = async () => {
    if (!name && !email && !message) return;
    const status = await sendMessage({ name, email, message });
    if (status === 201) {
      window.scrollTo(0, 0);
      window.location.reload();
    }
  };

  const {
    emailPlaceholder,
    messagePlaceholder,
    namePlaceholder,
    submitButtonText,
    title,
  } = AppCopy.contact;
  return (
    <Section>
      <div className={ClassName.container}>
        <img
          src={Images.contactImage}
          alt="contact"
          className={ClassName.contactImage}
        />
        <div className={ClassName.contactFormContainerRelative}>
          <div className={ClassName.contactFormContainerAbsolute}>
            <div className={ClassName.contactForm}>
              <div className={ClassName.title}>{title}</div>
              <input
                type="text"
                value={name}
                onChange={onNameChange}
                placeholder={namePlaceholder}
                className={ClassName.input}
              />
              <input
                type="text"
                value={email}
                onChange={onEmailChange}
                placeholder={emailPlaceholder}
                className={ClassName.input}
              />
              <textarea
                value={message}
                onChange={onMessageChange}
                placeholder={messagePlaceholder}
                className={ClassName.textarea}
                rows={5}
              />
              <button className={ClassName.submitButton} onClick={OnSubmit}>
                {submitButtonText}
              </button>
              <div className={ClassName.filler}></div>
              <div className={ClassName.formContact}>
                <img
                  src={Images.contactPhoneIcon}
                  alt="phone"
                  className={ClassName.formContactIcon}
                />
                <span className={ClassName.formContactText}>
                  {Contact.phoneNumber}
                </span>
              </div>
              <div className={ClassName.formContact}>
                <img
                  src={Images.contactEmailIcon}
                  alt="phone"
                  className={ClassName.formContactIcon}
                />
                <span className={ClassName.formContactText}>
                  {Contact.email}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContactSection;

const ClassName = {
  container:
    "w-full h-500px sm:h-675px bg-tertiary flex flex-row items-start justify-start my-52 font-secondary relative",
  contactImage:
    "h-full w-full absolute md:relative md:w-45percent object-cover",
  contactFormContainerRelative: "flex flex-row h-full flex-1 relative",
  contactFormContainerAbsolute:
    "absolute w-full flex flex-col items-center top-20 sm:top-40 xl:top-60",
  contactForm:
    "h-500px sm:h-675px w-90percent sm:w-10/12 bg-white shadow-lg box-border p-6 sm:p-8 xl:p-10 flex flex-col",
  title:
    "font-primary text-primary text-3xl md:text-4xl mb-4 sm:mb-6 uppercase",
  input:
    "border-secondary border h-12 sm:h-14 w-full px-4 sm:px-6 text-primary text-xs sm:text-sm mb-4 sm:mb-6",
  textarea:
    "border-secondary border w-full p-4 sm:p-6 pt-2 sm:pt-4 text-primary text-sm mb-4 sm:mb-6",
  submitButton: "button-primary",
  filler: "flex flex-col flex-1",
  formContact: "flex flex-row items-center my-2",
  formContactIcon: "h-3 w-3 sm:h-5 sm:w-5 object-contain mr-2 sm:mr-4",
  formContactText: "text-sm sm:text-base text-primary",
};
