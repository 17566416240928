import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CachedItem, {
  LoadCachedItem,
  LoadCachedItemSession,
  SessionCacheItem,
} from "../config/cache";
import { setCart } from "../reducers/orderReducer";
import { setProduct } from "../reducers/productReducer";
import { setPhoneNumber } from "../reducers/pageReducer";
import { setToken, setUser } from "../reducers/userReducer";

export default function useLoadCache() {
  const dispatch = useDispatch();

  const loadToken = () => {
    const data = LoadCachedItem(CachedItem.token, true);
    data && dispatch(setToken(data));
  };

  const loadUser = () => {
    const data = LoadCachedItem(CachedItem.user);
    data && dispatch(setUser(data));
  };

  const loadCart = () => {
    const data = LoadCachedItem(CachedItem.cart);
    dispatch(setCart(data));
  };

  const loadCachedProduct = () => {
    const data = LoadCachedItemSession(SessionCacheItem.product);
    dispatch(setProduct(data));
  };

  const loadPhoneNumber = () => {
    const data = LoadCachedItem(CachedItem.phone);
    if (!data) return;
    dispatch(setPhoneNumber(data));
  };

  useEffect(() => {
    loadCart();
    loadCachedProduct();
    loadPhoneNumber();
    loadToken();
    loadUser();
  }, []);

  return undefined;
}
