import { useDispatch } from "react-redux";
import { sendMessageProps } from "../declarations/messageServices";
import { setProgressBarActive } from "../reducers/pageReducer";
import { SendMessage } from "../api/services/message";

export const useMessages = () => {
  const dispatch = useDispatch();

  const sendMessage = async ({
    email,
    message,
    name,
  }: sendMessageProps): Promise<number | undefined> => {
    dispatch(setProgressBarActive(true));
    const response = await SendMessage({
      contact: email,
      name,
      message,
    });
    const { status } = response;
    dispatch(setProgressBarActive(false));
    return status;
  };

  return {
    sendMessage,
  };
};
