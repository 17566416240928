import { OrderCardProps } from "../../declarations/card";
import MoreIcon from "../../assets/More.svg";
import LocationIcon from "../../assets/LocationIcon.svg";
import PhoneIcon from "../../assets/Phoneicon.svg";
import { getOrderDate } from "../../functions/time";
import { Capitalize } from "../../functions/text";
import { useDispatch } from "react-redux";
import { setOrder, setOrderModal } from "../../reducers/pageReducer";
import AppCopy from "../../copy/AppCopy";

function OrderCard({
  order,
  customerClassname,
  productsClassname,
  deliveryManClassname,
  statusClassname,
  orderDateClassname,
  moreClassname,
}: OrderCardProps) {
  const dispatch = useDispatch();

  const { customer, deliveryMan, orderDate, products, status } = AppCopy.orders;

  const getProducts = () => {
    let products = order.products;
    let productNames = products.map((product) => product.productName);
    return productNames.join(", ");
  };

  const StatusStyle = () => {
    if (order.status === "ordered") {
      return ClassName.statusOrdered;
    }
    if (order.status === "delivered") {
      return ClassName.statusDelivered;
    }
    if (order.status === "cancelled") {
      return ClassName.statusCancelled;
    }
    if (order.status === "assigned") {
      return ClassName.statusAssigned;
    }
    return "";
  };

  const onClick = () => {
    dispatch(setOrder(order));
    dispatch(setOrderModal(true));
  };

  return (
    <div className={ClassName.container} onClick={onClick}>
      <div className={`${ClassName.customer} ${customerClassname}`}>
        <div className={ClassName.title}>{customer}:</div>
        <div className={ClassName.addressContainer}>
          <img src={LocationIcon} alt="" className={ClassName.locationIcon} />
          <div>{order.deliveryAddress}</div>
        </div>
        <div className={ClassName.phoneContainer}>
          <img src={PhoneIcon} alt="" className={ClassName.phoneIcon} />
          <div>{order.phoneNumber}</div>
        </div>
      </div>
      <div className={`${ClassName.products} ${productsClassname}`}>
        <div className={ClassName.title}>{products}</div>
        <div>{getProducts()}</div>
      </div>
      <div className={ClassName.title}>{deliveryMan}</div>
      <div className={`${ClassName.deliveryMan} ${deliveryManClassname}`}>
        Random
      </div>
      <div className={ClassName.title}>{status}</div>
      <div className={`${ClassName.status} ${statusClassname}`}>
        <div className={`${ClassName.statusContainer} ${StatusStyle()}`}>
          {Capitalize(order.status)}
        </div>
      </div>
      <div className={ClassName.title}>{orderDate}</div>
      <div className={`${ClassName.orderDate} ${orderDateClassname}`}>
        {getOrderDate(order.createdAt)}
      </div>
      <div className={`${ClassName.more} ${moreClassname}`}>
        <img src={MoreIcon} alt="" className={ClassName.moreIcon} />
      </div>
    </div>
  );
}

export default OrderCard;

const ClassName = {
  container:
    "w-full flex flex-col lg:flex-row items-start lg:items-center justify-between border-b border-bprimary py-2 cursor-pointer",
  customer: "flex flex-col",

  addressContainer: "flex flex-row font-medium text-sm sm:text-base",
  locationIcon: "w-3 sm:w-4 h-3 sm:h-4 container mr-2 mt-1",
  phoneContainer: "flex flex-row font-medium text-sm sm:text-base",
  phoneIcon: "w-3 sm:w-4 h-3 sm:h-4 container mr-2 mt-1.5",
  title: "flex lg:hidden text-secondaryText text-xs mb-1",

  products: "",
  deliveryMan: "",
  status: "",

  statusContainer:
    "border rounded-full w-24 h-6 flex flex-row items-center justify-center rounded-full text-xs",
  statusOrdered: "text-statusSuccess border-statusSuccess",
  statusDelivered: "text-secondaryText border-secondaryText",
  statusAssigned: "text-statusProgress border-statusProgress",
  statusCancelled: "text-statusCancelled border-statusCancelled",

  orderDate: "text-xs sm:text-sm",
  more: "",
  moreIcon: "w-8 h-8 contain",
};
