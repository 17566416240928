import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import AppCopy from "../copy/AppCopy";
import { GetProps } from "../declarations/commonServices";
import { GetProducts } from "../api/services/product";
import { setTopProducts } from "../reducers/statsReducer";
import { Sort } from "../config/api";

export function useStats() {
  const dispatch = useDispatch();
  const { stats, topProducts } = useSelector((state: RootState) => state.stats);

  const {
    totalAssignedText,
    totalCancelledText,
    totalDeliveryPeopleText,
    totalExpensesText,
    totalMessagesText,
    totalOrdersText,
    totalPendingText,
    totalRevenueText,
    totalProductsText,
    totalDeliveredText,
  } = AppCopy.stats;
  const getStats = (): {
    title: string;
    amount: string | number | undefined;
  }[] => {
    let stat: { title: string; amount: string | number | undefined }[] = [];

    stat.push({
      title: totalRevenueText,
      amount: stats.totalRevenue,
    });
    stat.push({
      title: totalExpensesText,
      amount: stats.totalExpenses,
    });
    stat.push({
      title: totalProductsText,
      amount: stats.totalProducts,
    });
    stat.push({
      title: totalOrdersText,
      amount: stats.totalOrders,
    });
    stat.push({
      title: totalAssignedText,
      amount: stats.totalAssigned,
    });
    stat.push({
      title: totalPendingText,
      amount: stats.totalPending,
    });
    stat.push({
      title: totalCancelledText,
      amount: stats.totalCancelled,
    });
    stat.push({
      title: totalDeliveredText,
      amount: stats.totalDelivered,
    });
    stat.push({
      title: totalDeliveryPeopleText,
      amount: stats.totalDeliveryPeople,
    });
    stat.push({
      title: totalPendingText,
      amount: stats.totalPending,
    });
    stat.push({
      title: totalMessagesText,
      amount: stats.totalMessages,
    });

    return stat;
  };

  const getTop5Products = async ({
    category,
    limit,
    offset,
    populate,
    search,
    setLoading,
    sort,
    type,
  }: GetProps) => {
    if (!topProducts || topProducts.length === 0) {
      const response = await GetProducts({
        setLoading: setLoading,
        category,
        limit,
        offset,
        populate,
        search,
        sort: Sort.descendingOrder,
        type,
      });
      const { products, message, status } = response;

      if (status === 200) {
        let topProducts = products.slice(0, 5);
        dispatch(setTopProducts(topProducts));
      }
    }
  };

  return {
    getStats,
    getTop5Products,
  };
}
