import React from "react";
import { OrderListProps } from "../../declarations/list";
import OrderCard from "../card/OrderCard";
import AppCopy from "../../copy/AppCopy";
import Chevron from "../../assets/ChevronDown.svg";
import { divId } from "../../config/ui";

function OrderList({ data, loading, more, onLoadMore }: OrderListProps) {
  const { customer, deliveryMan, orderDate, products, status } = AppCopy.orders;

  const handleLoadMore = () => {
    if (loading) return;
    onLoadMore && onLoadMore();
  };

  return (
    <div className={ClassName.container}>
      <div className={ClassName.headerContainer}>
        <div className={ClassName.header}>
          <div className={ClassName.customerClassname}>{customer}</div>
          <div className={ClassName.productsClassname}>{products}</div>
          <div className={ClassName.deliveryManClassname}>{deliveryMan}</div>
          <div className={ClassName.statusClassname}>{status}</div>
          <div className={ClassName.orderDateClassname}>{orderDate}</div>
          <div className={ClassName.moreClassname}></div>
        </div>
      </div>
      <div className={ClassName.contentContainer} id={divId.orderList}>
        <div className={ClassName.content}>
          {data.map((order, index) => (
            <OrderCard
              key={index}
              order={order}
              customerClassname={ClassName.customerClassname}
              productsClassname={ClassName.productsClassname}
              deliveryManClassname={ClassName.deliveryManClassname}
              statusClassname={ClassName.statusClassname}
              orderDateClassname={ClassName.orderDateClassname}
              moreClassname={ClassName.moreClassname}
            />
          ))}
          <div className={ClassName.flex}></div>
          {more && (
            <div className={ClassName.loadMore} onClick={handleLoadMore}>
              {loading ? (
                <div className={ClassName.loader}></div>
              ) : (
                <img src={Chevron} alt="" className={ClassName.loadMoreIcon} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderList;

const ClassName = {
  container: "w-full flex flex-col text-sm sm:text-base",
  headerContainer: "w-full mr-6",
  header:
    "border-b border-bprimary py-2 mb-2 mr-6 hidden lg:flex flex-row items-center justify-between",
  contentContainer: "w-full overflow-scroll scrollbar-card",
  content: "lg:mr-6",
  customerClassname: "w-full lg:w-25percent mb-2 flex flex-col",
  productsClassname: "w-full lg:w-20percent mb-2 flex flex-col",
  deliveryManClassname: "w-full lg:w-10percent mb-2 flex flex-col",
  statusClassname: "w-full lg:w-10percent mb-2 flex flex-col",
  orderDateClassname: "w-full lg:w-15percent mb-2 flex flex-col",
  moreClassname: "w-8 hidden lg:flex",
  loadMore:
    "w-full py-6 flex flex-row items-center justify-center cursor-pointer",
  loadMoreIcon: "w-4 contain",
  loader: "loader",
  flex: "flex flex-col flex-1 grow",
};
