import React, { useEffect } from "react";
import { DashboardHeaderProps } from "../../declarations/Header";
import Menu from "../../assets/Menu.svg";
import { useDispatch } from "react-redux";
import { setSidebarActive } from "../../reducers/pageReducer";
import io from "socket.io-client";
import { SocketEvents, SocketURL } from "../../config/socket";
import useOrder from "../../hook/useOrder";

function DashboardHeader({ sections, pageName }: DashboardHeaderProps) {
  const dispatch = useDispatch();
  const { getRecentOrders } = useOrder();

  useEffect(() => {
    const socket = io(SocketURL());

    socket.on(SocketEvents.Connect, () => {
      console.log("connected");
    });

    socket.on(SocketEvents.newOrder, (data) => {
      getRecentOrders(true);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const onMenuPress = () => {
    dispatch(setSidebarActive(true));
  };
  return (
    <div className={ClassName.container}>
      <div className={ClassName.leftContainer}>
        <img
          src={Menu}
          alt="Menu"
          className={ClassName.menu}
          onClick={onMenuPress}
        />
        <div className={ClassName.pageName}>{pageName}</div>
      </div>
      <div className={ClassName.rightContainer}>
        <div className={ClassName.filler}></div>
        {sections?.map((section, index) => (
          <div key={index}>{section}</div>
        ))}
      </div>
    </div>
  );
}

export default DashboardHeader;

const ClassName = {
  container:
    "border-b left-0 top-0 border-borderSecondary bg-dbackground w-full flex flex-col md:flex-row items-start md:items-center py-2 px-3 sm:px-4 lg:px-0 items-center justify-between fixed lg:relative z-20",
  leftContainer: "flex w-full md:w-fit flex-row items-center",
  rightContainer:
    "flex flex-1 w-full md:w-fit mt-2 md:mt-0 flex-row items-center justify-between",
  filler: "hidden md:flex",
  pageName: "text-xl sm:text-4xl font-semibold text-secondaryText",
  menu: "flex xl:hidden w-5 sm:w-7 object contain mr-3 cursor-pointer",
};
