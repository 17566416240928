import { CommonStyles } from "../styles/CommonStyles";
import Header from "../components/common/Header";
import HomeHeroSection from "../sections/HomeHeroSection";
import HomeCategorySection from "../sections/HomeCategorySection";
import { useEffect, useRef } from "react";
import Footer from "../components/common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage, setSelectedSection } from "../reducers/pageReducer";
import { Paths } from "../router/Paths";
import ContactSection from "../sections/ContactSection";
import HomeAboutUsSection from "../sections/HomeAboutUsSection";
import HomeFAQSection from "../sections/HomeFAQSection";
import { useGetProducts } from "../hook/useGetProducts";
import ProgressBar from "../components/common/ProgressBar";
import { RootState } from "../app/store";
import { CommonHeaderTabs } from "../constants/tabs";

function HomePage() {
  const dispatch = useDispatch();
  const { selectedSection } = useSelector((state: RootState) => state.page);
  const { getProductsWithDispatch } = useGetProducts();

  const faqRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    dispatch(setActivePage(Paths.home));
    window.scrollTo(0, 0);
    getProductsWithDispatch({});
  }, [dispatch]);

  const scrollSectionIntoView = () => {
    if (selectedSection === CommonHeaderTabs.faqs.name) {
      if (faqRef.current) {
        (faqRef.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
      }
    } else if (selectedSection === CommonHeaderTabs.contactus.name) {
      if (contactRef.current) {
        (contactRef.current as HTMLElement).scrollIntoView({
          behavior: "smooth",
        });
      }
    }
    dispatch(setSelectedSection(null));
  };

  useEffect(() => {
    if (selectedSection) {
      scrollSectionIntoView();
    }
  }, [selectedSection]);

  return (
    <div className={`${CommonStyles.pageWrapper}`}>
      <ProgressBar />
      <Header shrinkAnimation />
      <HomeHeroSection />
      <HomeCategorySection />
      <HomeAboutUsSection />
      <div ref={faqRef}></div>
      <HomeFAQSection />
      <div ref={contactRef}></div>
      <ContactSection />
      <Footer />
    </div>
  );
}

export default HomePage;
