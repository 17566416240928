import React, { useEffect, useState } from "react";
import { CommonStyles } from "../styles/CommonStyles";
import AppCopy from "../copy/AppCopy";
import DashboardHeader from "../components/common/DashboardHeader";
import Chevron from "../assets/ChevronDown.svg";
import { OrderStatuses } from "../config/status";
import { Capitalize } from "../functions/text";
import { OrderStatusType, OrderType } from "../declarations/orderServices";
import useOrder from "../hook/useOrder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import OrderList from "../components/list/OrderList";
import OrderModal from "../components/modal/OrderModal";
import { setActiveTab, setProgressBarActive } from "../reducers/pageReducer";
import { SideBarTabs } from "../constants/tabs";
import { GetProps } from "../declarations/commonServices";
import { setOrders } from "../reducers/orderReducer";
import { io } from "socket.io-client";
import { SocketEvents, SocketURL } from "../config/socket";
import { divId } from "../config/ui";

function DashboardOrdersPage() {
  const dispatch = useDispatch();
  const { title } = AppCopy.dashboardOrders;
  const { getOrdersWithDispatch, getOrders } = useOrder();
  const { orders } = useSelector((state: RootState) => state.order);

  //states
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [status, setStatus] = useState<OrderStatusType | string>("all");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadedOrders, setLoadedOrders] = useState<OrderType[] | null>(orders);

  const loadData = async () => {
    let query: GetProps = {};

    if (status !== "all") {
      query.status = status;
    } else {
      setLoadedOrders(orders);
    }

    dispatch(setProgressBarActive(true));
    const newOrders = await getOrders({ ...query });
    dispatch(setProgressBarActive(false));
    if (newOrders) {
      if (status === "all") {
        dispatch(setOrders(newOrders));
      }
      setLoadedOrders(newOrders);
    }
  };

  const scrollToTop = () => {
    const divElement = document.getElementById(divId.orderList);
    if (divElement) {
      divElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const socket = io(SocketURL());

    socket.on(SocketEvents.newOrder, (data) => {
      loadedOrders
        ? setLoadedOrders([data, ...loadedOrders])
        : setLoadedOrders([data]);
      scrollToTop();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    setLoadedOrders(orders);
  }, [orders]);

  useEffect(() => {
    loadData();
  }, [status]);

  useEffect(() => {
    getOrdersWithDispatch({});
    dispatch(setActiveTab(SideBarTabs.Orders.name));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setTimeout(() => {
        if (dropdown) {
          setDropdown(false);
        }
      }, 100);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const onStatusDropdown = () => {
    setDropdown(true);
  };

  const onStatusSelect = (statusText: OrderStatusType | string) => {
    setDropdown(false);
    setStatus(statusText);
  };

  const onLoadMoreOrders = async () => {
    let query: GetProps = {
      setLoading: setLoading,
      offset: loadedOrders?.length,
    };
    if (status && status !== "all") {
      query.status = status;
    }
    const newOrders = await getOrders(query);
    if (newOrders && loadedOrders) {
      const updatedOrders = [...loadedOrders, ...newOrders];
      if (status !== "all") {
        setLoadedOrders(updatedOrders);
        return;
      }
      dispatch(setOrders(updatedOrders));
    }
  };

  const Dropdown = () => {
    return (
      <div className={ClassName.inputContainer}>
        <div
          className={`${ClassName.input} ${ClassName.selectorInput}`}
          onClick={onStatusDropdown}
        >
          <div>{Capitalize(status)}</div>
          <img src={Chevron} alt="" className={ClassName.chevron} />
        </div>
        {dropdown && (
          <div className={ClassName.dropdown}>
            <div
              className={ClassName.dropdownSelectable}
              onClick={() => onStatusSelect("all")}
            >
              All
            </div>
            {OrderStatuses.map((orderStatus, index) => (
              <div
                key={index}
                className={ClassName.dropdownSelectable}
                onClick={() => onStatusSelect(orderStatus)}
              >
                {Capitalize(orderStatus)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      className={`${CommonStyles.dashBoardPageWrapper} ${ClassName.container}`}
    >
      <OrderModal />
      <DashboardHeader pageName={title} sections={[<Dropdown />]} />
      <div className={ClassName.bottomContent}>
        <div className={ClassName.ordersContainer}>
          <OrderList
            data={loadedOrders || []}
            more={loadedOrders && loadedOrders?.length > 10 ? true : false}
            onLoadMore={onLoadMoreOrders}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardOrdersPage;

const ClassName = {
  container: "pt-24 ",
  inputContainer: "text-secondaryText text-sm mb-2",
  input:
    "text-dprimary h-8 w-64 shrink-0 rounded-md border border-borderPrimary flex flex-row items-center px-3 mt-1 mb-2 bg-white",
  dropdowns: "flex flex-row justify-between relative",
  dropdown:
    "w-64 max-h-40 top-60 p-3 overflow-scroll absolute border border-borderPrimary bg-white rounded-md",
  dropdownSelectable: "text-sm text-secondaryText cursor-pointer py-1 w-full",

  selectorInput: "flex flex-row justify-between cursor-pointer",
  chevron: "w-2 contain ml-2",

  bottomContent:
    "w-full h-fit md:h-90percent flex flex-col bg-white rounded-xl px-4 md:px-8 mt-4",
  ordersContainer: "flex flex-1 h-fit lg:h-1/2 mt-4",
};
