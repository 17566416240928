import { AxiosResponse } from "axios";
import { Requests } from "../../config/api";
import { postRequest } from "../commonActions/post";
import { LoginProps, LoginReturnType } from "../../declarations/userServices";

export const Login = async ({
  setLoading,
  password,
  username,
}: LoginProps): Promise<LoginReturnType> => {
  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await postRequest({
      route: Requests.post.login,
      payload: { password, username },
    });
    setLoading && setLoading(false);

    const user = response?.data?.user;
    const token = response?.data?.token;
    const status = response?.status;
    const message = response?.data?.msg;

    return {
      status: status,
      user,
      token,
      message: message,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};
