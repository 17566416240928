import { useNavigate } from "react-router-dom";
import { Paths } from "../router/Paths";

export function useAdminNavigate() {
  const navigate = useNavigate();
  const adminNavigate = (path: string) => {
    return navigate(`${Paths.adminPath}${path}`);
  };
  return adminNavigate;
}
