import { SectionProps } from "../../declarations/CommonComponents";

function Section({ parentClassname, classname, children }: SectionProps) {
  return (
    <div className={`w-full flex flex-col items-center ${parentClassname}`}>
      <div
        className={`percent-width-90 max-w-7xl flex flex-col items-center ${classname}`}
      >
        {children}
      </div>
    </div>
  );
}

export default Section;
