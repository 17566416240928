import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartItemType, OrderType } from "../declarations/orderServices";

interface OrderState {
  orders: OrderType[];
  cart: CartItemType[];
}

const initialState: OrderState = {
  orders: [],
  cart: [],
};

const order = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<OrderType[]>) => {
      state.orders = action.payload;
    },
    setCart: (state, action: PayloadAction<CartItemType[]>) => {
      state.cart = action.payload;
    },
  },
});

export const { setOrders, setCart } = order.actions;

export default order.reducer;
