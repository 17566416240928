import React, { useEffect } from "react";
import Logo from "../../assets/LogoWhite.svg";
import Profile from "../../assets/ProfilePlaceholder.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Capitalize } from "../../functions/text";
import AppCopy from "../../copy/AppCopy";
import { SideBarTabs, SidebarTabsType } from "../../constants/tabs";
import { setActiveTab, setSidebarActive } from "../../reducers/pageReducer";
import Logout from "../../assets/LogoutTabIcon.svg";
import { useUser } from "../../hook/useUser";
import { useAdminNavigate } from "../../hook/useAdminNavigate";
import { Paths } from "../../router/Paths";
import { useNavigate } from "react-router-dom";

function SideBar() {
  const dispatch = useDispatch();
  const navigate = useAdminNavigate();
  const navigate1 = useNavigate();
  const { logout } = useUser();
  const { user } = useSelector((state: RootState) => state.user);
  const { activeTab, sidebarActive } = useSelector(
    (state: RootState) => state.page
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setTimeout(() => {
        if (sidebarActive) {
          dispatch(setSidebarActive(false));
        }
      }, 100);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarActive]);

  const handleTabSelect = (tab: SidebarTabsType) => {
    dispatch(setActiveTab(tab));
    switch (tab) {
      case SideBarTabs.Overview.name:
        navigate(Paths.dashboardHome);
        break;
      case SideBarTabs.Products.name:
        navigate(Paths.dashboardProducts);
        break;
      case SideBarTabs.Expenses.name:
        navigate(Paths.dashboardHome);
        break;
      case SideBarTabs.Revenue.name:
        navigate(Paths.dashboardHome);
        break;
      case SideBarTabs.DeliveryPeople.name:
        navigate(Paths.dashboardHome);
        break;
      case SideBarTabs.Orders.name:
        navigate(Paths.dashboardOrders);
        break;
      case SideBarTabs.Messages.name:
        navigate(Paths.dashboardHome);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleOnLogoClick = () => {
    navigate1("/");
  };

  const { logoutText, titleText } = AppCopy.sideBar;
  return (
    <div
      className={`${ClassName.container} ${
        !sidebarActive && ClassName.hideSideBar
      }`}
    >
      <img
        src={Logo}
        className={ClassName.logo}
        alt="Logo"
        onClick={handleOnLogoClick}
      />

      <div className={ClassName.profileContainer}>
        <div className={ClassName.imageContainer}>
          <img src={Profile} alt="" className={ClassName.profile} />
        </div>
        <div className={ClassName.nameContainer}>
          <span className={ClassName.name}>{Capitalize(user?.fullName)}</span>
          <span className={ClassName.role}>{Capitalize(user?.role)}</span>
        </div>
      </div>

      <span className={ClassName.title}>{titleText}</span>
      {Object.values(SideBarTabs).map((tab, index) => (
        <div
          key={index}
          className={`${ClassName.tabContainer} ${
            activeTab === tab.name && ClassName.activeTabContainer
          }`}
          onClick={() => handleTabSelect(tab.name)}
        >
          <img src={tab.icon} alt="" className={ClassName.tabIcon} />
          <span className={ClassName.tabName}>{tab.name}</span>
        </div>
      ))}

      <div className={ClassName.filler}></div>

      <div className={ClassName.tabContainer} onClick={handleLogout}>
        <img src={Logout} alt="" className={ClassName.tabIcon} />
        <span className={ClassName.tabName}>{logoutText}</span>
      </div>
    </div>
  );
}

export default SideBar;

const ClassName = {
  container:
    "bg-dprimary w-90vw sm:w-350px p-5 flex flex-col text-white h-100vh fixed xl:relative z-50",
  hideSideBar: "hidden xl:flex",
  logo: "w-16 ml-5 object-contain mb-6 shrink-0 cursor-pointer",
  profileContainer:
    "border-y border-white w-full py-5 px-5 flex flex-rol items-center",
  imageContainer:
    "flex items-center justify-center rounded-full w-16 h-16 bg-dsecondary",
  profile: "contain w-6 h-6",
  nameContainer: "flex flex-col ml-5",
  name: "text-base",
  role: "text-sm text-borderPrimary",
  title: "text-base text-borderPrimary mb-5 pt-5",
  tabContainer:
    "w-full h-10 rounded-xl flex flex-row items-center px-5 cursor-pointer",
  activeTabContainer: "bg-dsecondary",
  tabIcon: "h-4 w-4 contain",
  tabName: "ml-4 font-medium",
  filler: "flex flex-1",
};
