import { useNavigate } from "react-router-dom";
import AppCopy from "../../copy/AppCopy";
import { ProductProps } from "../../declarations/CommonComponents";
import { Paths } from "../../router/Paths";
import { getPriceWithCurrency } from "../../functions/currency";
import { ProductTarget } from "../../constants/productType";
import { useDispatch } from "react-redux";
import { setProduct } from "../../reducers/productReducer";
import useCart from "../../hook/useCart";
import { CacheItemSession, SessionCacheItem } from "../../config/cache";

function Product({ product }: ProductProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    addToCart({
      price: product.sizes ? product?.sizes[0]?.price : product.price,
      product: product,
      productName: product.name,
      quantity: 1,
      selectedSize:
        product.availableSizes && product.sizes && product.sizes?.length > 0
          ? product?.sizes[0].sizeName
          : "",
    });
  };

  const OnProductPress = () => {
    let name = product.name;
    name = name.split(" ").join("-");
    dispatch(setProduct(product));
    CacheItemSession(SessionCacheItem.product, product);
    navigate(`${Paths.product}/${name}/${product._id}`);
  };

  const { addToCartButtonText } = AppCopy.shop;
  return (
    <div
      className={`${ClassName.container} ${
        product.type === ProductTarget.feminine
          ? ClassName.feminineBorder
          : ClassName.masculineBorder
      }`}
    >
      <div
        className={`${ClassName.imageContainer} ${
          product.type === ProductTarget.feminine
            ? ClassName.feminine
            : ClassName.masculine
        }`}
      >
        {product?.images && (
          <img
            src={product?.images[0]}
            alt=""
            className={ClassName.productImage}
            onClick={OnProductPress}
          />
        )}
      </div>
      <div className={ClassName.productName}>{product.name}</div>
      <div className={ClassName.priceContainer}>
        <div
          className={`${ClassName.price} ${
            product.type === ProductTarget.masculine
              ? ClassName.masculineText
              : ClassName.feminineText
          }`}
        >
          {getPriceWithCurrency(product.price)}
        </div>
        <button
          className={`${ClassName.button} ${
            product.type === ProductTarget.masculine
              ? ClassName.masculineButton
              : ClassName.feminineButton
          }`}
          onClick={handleAddToCart}
        >
          {addToCartButtonText}
        </button>
      </div>
    </div>
  );
}

export default Product;

const ClassName = {
  container:
    "w-1/2 md:w-72 md:w-80 border-box border p-1 md:p-3 font-secondary md:m-6",
  feminineBorder: "border-tertiary",
  masculineBorder: "border-quinary",
  imageContainer: "flex items-center justify-center h-200px md:h-305px w-full",
  feminine: "bg-tertiary",
  masculine: "bg-quinary",
  feminineText: "text-primary",
  masculineText: "text-black",
  feminineButton: "bg-primary",
  masculineButton: "bg-black",
  productName: "mt-2 md:mt-4 mb-2 md:mb-4 text-xs md:text-base",
  productImage: "w-2/3 h-2/3 object-contain cursor-pointer",
  priceContainer: "flex flex-row w-full items-end justify-between mb-2",
  price: "text-sm md:text-xl font-semibold",
  button:
    "text-white px-3 md:px-6 py-1 md:py-2 cursor-pointer text-xs md:text-sm",
};
