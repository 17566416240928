import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StatType } from "../declarations/statServices";
import { getPriceWithCurrency } from "../functions/currency";
import { ProductType } from "../declarations/productService";
import { OrderType } from "../declarations/orderServices";

interface StatsState {
  stats: StatType;
  topProducts: ProductType[];
  recentOrders: OrderType[];
}

const initialState: StatsState = {
  stats: {
    totalRevenue: getPriceWithCurrency(0),
    totalExpenses: getPriceWithCurrency(0),
    totalProducts: 0,
    totalDeliveryPeople: 0,
    totalOrders: 0,
    totalCancelled: 0,
    totalDelivered: 0,
    totalAssigned: 0,
    totalPending: 0,
    totalMessages: 0,
  },
  topProducts: [],
  recentOrders: [],
};

const stats = createSlice({
  name: "stats",
  initialState: initialState,
  reducers: {
    setStats: (state, action: PayloadAction<StatType>) => {
      state.stats = action.payload;
    },
    setTopProducts: (state, action: PayloadAction<ProductType[]>) => {
      state.topProducts = action.payload;
    },
    setRecentOrders: (state, action: PayloadAction<OrderType[]>) => {
      state.recentOrders = action.payload;
    },
  },
});

export const { setStats, setTopProducts, setRecentOrders } = stats.actions;

export default stats.reducer;
