import React, { useEffect } from "react";
import { CommonStyles } from "../styles/CommonStyles";
import DashboardHeader from "../components/common/DashboardHeader";
import AppCopy from "../copy/AppCopy";
import { useStats } from "../hook/useStats";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import useOrder from "../hook/useOrder";
import OrderList from "../components/list/OrderList";
import OrderModal from "../components/modal/OrderModal";
import { setActiveTab, setProgressBarActive } from "../reducers/pageReducer";
import { SideBarTabs } from "../constants/tabs";

function DashboardHomePage() {
  const dispatch = useDispatch();
  const { getStats, getTop5Products } = useStats();
  const { getRecentOrders } = useOrder();
  const { topProducts, recentOrders: orders } = useSelector(
    (state: RootState) => state.stats
  );

  const { title, recentOrders, top5Products } = AppCopy.dashboardOverview;

  const loadData = async () => {
    dispatch(setProgressBarActive(true));
    await getTop5Products({});
    await getRecentOrders();
    dispatch(setProgressBarActive(false));
  };

  useEffect(() => {
    loadData();
    dispatch(setActiveTab(SideBarTabs.Overview.name));
  }, [setActiveTab]);

  return (
    <div
      className={`${CommonStyles.dashBoardPageWrapper} ${ClassName.container}`}
    >
      <DashboardHeader pageName={title} />
      <OrderModal />
      <div className={ClassName.content}>
        <div className={ClassName.topContent}>
          <div className={ClassName.leftContent}>
            <div className={ClassName.gridContainer}>
              {getStats().map((stat, index) => (
                <div key={index} className={ClassName.statContainer}>
                  <div className={ClassName.statBar}></div>
                  <div className={ClassName.statContent}>
                    <div className={ClassName.statTitle}>{stat.title}</div>
                    <div className={ClassName.statAmount}>{stat.amount}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={ClassName.rightContent}>
            <div className={ClassName.productsTitle}>{top5Products}</div>
            <div className={ClassName.productsContainer}>
              {topProducts.map((product, index) => (
                <div key={index} className={ClassName.productContainer}>
                  <span className={ClassName.productNumber}>{index + 1}. </span>
                  {product.images && (
                    <img
                      src={product.images[0]}
                      alt=""
                      className={ClassName.productImage}
                    />
                  )}
                  <div>{product.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={ClassName.bottomContent}>
          <div className={ClassName.ordersTitle}>{recentOrders}</div>
          <div className={ClassName.ordersContainer}>
            <OrderList data={orders} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHomePage;

const ClassName = {
  container: "",
  content: "flex-1 flex flex-col justify-evenly",
  topContent:
    "w-full h-fit lg:h-40vh flex flex-col lg:flex-row items-center justify-between gap-4 lg:gap-8 ",
  gridContainer: "grid grid-cols-2 sm:grid-cols-3 gap-4 w-full justify-between",
  leftContent:
    "flex flex-1 w-full flex-row bg-white rounded-xl h-full p-6 sm:p-8 mt-4 lg:mt-0",
  statContainer: "flex flex-row flex-1 items-center",
  statBar: "w-1.5 bg-tertiary h-10 sm:h-12 mr-3 sm:mr-5 rounded-full",
  statContent: "flex flex-col",
  statTitle: "text-dsecondary text-xs sm:text-sm",
  statAmount: "text-dprimary text-sm sm:text-xl",
  rightContent:
    "flex w-full lg:w-2/5 flex-col bg-white rounded-xl h-full px-6 sm:px-8 py-4",
  productsTitle:
    "font-semibold text-base sm:text-xl text-secondaryText pb-1 sm:pb-2 border-b border-primaryBorder w-full",
  productsContainer:
    "flex flex-col flex-1 sm:overflow-scroll w-full scrollbar-card mt-4",
  productContainer:
    "flex w-full flex-row mb-4 text-xs sm:text-base text-dprimary",
  productNumber: "mr-4",
  productImage:
    "w-10 h-10 sm:w-12 sm:h-12 object-contain mr-4 bg-tertiary shrink-0",
  bottomContent:
    "w-full h-fit lg:h-40vh flex flex-col bg-white rounded-xl px-6 lg:px-8 py-4 pb-2 mt-4 lg:mt-0",
  ordersTitle:
    "font-semibold text-base sm:text-xl text-secondaryText pb-2 border-b border-primaryBorder w-full",
  ordersContainer: "flex flex-1 h-fit lg:h-1/2 mt-4",
};
