import { useNavigate } from "react-router-dom";
import Images from "../Images/Images";
import Section from "../components/common/Section";
import AppCopy from "../copy/AppCopy";
import { Paths } from "../router/Paths";
import { useDispatch } from "react-redux";
import { setProgressBarActive } from "../reducers/pageReducer";
import { useGetProducts } from "../hook/useGetProducts";

function HomeHeroSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getProductsWithDispatch } = useGetProducts();
  const handleShop = async () => {
    dispatch(setProgressBarActive(true));
    await getProductsWithDispatch({});
    dispatch(setProgressBarActive(false));
    navigate(Paths.shop);
  };

  const {
    heroDeliveryStatusText,
    heroShopButtonText,
    heroSubtitleText,
    heroTitleText,
  } = AppCopy.home;

  return (
    <div className={ClassName.container}>
      <Section classname={ClassName.content}>
        <div className={ClassName.contentContainer}>
          <div className={ClassName.contentLeft}>
            <div className={ClassName.deliveryContainer}>
              <img
                src={Images.delivery}
                alt=""
                className={ClassName.deliveryIcon}
              />
              <div className={ClassName.deliveryText}>
                {heroDeliveryStatusText}
              </div>
            </div>
            <div className={ClassName.contentLeftTitle}>{heroTitleText}</div>
            <div className={ClassName.contentLeftDescription}>
              {heroSubtitleText}
            </div>
            <button className={ClassName.shopButton} onClick={handleShop}>
              {heroShopButtonText}
            </button>
          </div>
          <img
            src={Images.heroImage}
            alt="hero"
            className={ClassName.heroImage}
          />
        </div>
      </Section>
    </div>
  );
}

export default HomeHeroSection;

const ClassName = {
  container:
    "min-h-80vh md:min-h-100vh w-full flex items-center justify-center gradient-hero-background",
  content: "flex flex-row items-center",
  contentContainer:
    "w-full flex flex-row items-center justify-between font-secondary",
  contentLeft:
    "flex flex-col justify-center items-center md:items-start md:max-w-60percent",
  contentLeftTitle:
    "font-primary text-center md:text-start text-5xl sm:text-6xl xl:text-8xl uppercase",
  contentLeftDescription:
    "text-quaternary text-center md:text-start text-sm sm:text-base xl:text-xl mt-10 w-2/3",
  contentRight: "flex flex-row ",
  deliveryContainer: "border border-black p-2 flex items-center mb-8",
  deliveryIcon: "w-6 h-6 sm:w-8 sm:h-8 object-contain mx-2 sm:mx-4",
  deliveryText: "mr-4 text-xs sm:text-sm xl:text-base",
  shopButton: "button-primary mt-10",
  heroImage: "hidden md:flex h-60vh lg:h-70vh object-contain",
};
