import React, { useEffect, useState } from "react";
import { CommonStyles } from "../styles/CommonStyles";
import AppCopy from "../copy/AppCopy";
import DashboardHeader from "../components/common/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setActiveTab, setProgressBarActive } from "../reducers/pageReducer";
import { SideBarTabs } from "../constants/tabs";
import NameContainerForm from "../components/shop/NameContainerForm";
import AvailableSizesForm from "../components/shop/AvailableSizesForm";
import StepsForm from "../components/shop/StepsForm";
import { useGetProducts } from "../hook/useGetProducts";
import { useAdminNavigate } from "../hook/useAdminNavigate";
import { useNavigate, useParams } from "react-router-dom";
import UpdateProductImages from "../components/shop/UpdateProductImages";
import { Paths } from "../router/Paths";

function DashboardUpdateProductPage() {
  const dispatch = useDispatch();
  const adminNavigate = useAdminNavigate();
  const params = useParams();
  const navigate = useNavigate();
  const { createProduct, getProduct, updateProduct } = useGetProducts();
  const { products, product } = useSelector(
    (state: RootState) => state.product
  );

  const {
    title,
    addProductButtonText,
    categoryErrorText,
    imagesErrorText,
    inStockErrorText,
    priceErrorText,
    productNameErrorText,
    typeErrorText,
  } = AppCopy.dashboardAddProduct;

  const { titleText, updateButtonText } = AppCopy.dashboardUpdateProduct;

  const getAvailableSizes = (
    allSizes: any
  ): { name: string; price: number }[] => {
    return allSizes?.map((size: any) => {
      return {
        name: size.sizeName,
        price: size.price,
      };
    });
  };

  //states
  const [image1, setImage1] = useState<File | string | undefined>(
    product?.images && product.images[0]
  );
  const [image2, setImage2] = useState<File | string | undefined>(
    product?.images && product.images[1]
  );
  const [image3, setImage3] = useState<File | string | undefined>(
    product?.images && product.images[2]
  );
  const [image4, setImage4] = useState<File | string | undefined>(
    product?.images && product.images[3]
  );
  const [name, setName] = useState<string>(product?.name as string);
  const [price, setPrice] = useState<number>(product?.price as number);
  const [amountInStock, setAmountInStock] = useState<number>(
    product?.stock as number
  );
  const [type, setType] = useState<string>(product?.type as string);
  const [category, setCategory] = useState<string>(product?.category as string);
  const [description, setDescription] = useState<string>(
    product?.description as string
  );
  const [sizesAvailable, setSizesAvailable] = useState<boolean>(
    product?.availableSizes as boolean
  );
  const [stepsAvailable, setStepsAvailable] = useState<boolean>(
    product?.howToUseSteps && product?.howToUseSteps?.length > 0 ? true : false
  );
  const [availableSizes, setAvailableSizes] = useState<
    { name: string; price: number }[]
  >(getAvailableSizes(product?.sizes));
  const [stepPre, setStepPre] = useState<string>(
    product?.howToUsePre as string
  );
  const [stepPost, setStepPost] = useState<string>(
    product?.howToUsePost as string
  );
  const [steps, setSteps] = useState<string[]>(
    product?.howToUseSteps as string[]
  );
  const [updating, setUpdating] = useState<boolean>(false);

  const updateStates = async () => {
    dispatch(setProgressBarActive(true));
    const loadedProduct = await getProduct(params.id as string);
    dispatch(setProgressBarActive(false));
    if (loadedProduct) {
      setName(loadedProduct.name);
      setPrice(loadedProduct.price);
      setAmountInStock(loadedProduct.stock as number);
      setType(loadedProduct.type);
      setCategory(loadedProduct.category);
      setDescription(loadedProduct.description);
      setSizesAvailable(loadedProduct.availableSizes as boolean);
      setStepsAvailable(
        loadedProduct.howToUseSteps && loadedProduct?.howToUseSteps?.length > 0
          ? true
          : false
      );
      setAvailableSizes(getAvailableSizes(loadedProduct.sizes));
      setStepPre(loadedProduct.howToUsePre as string);
      setStepPost(loadedProduct.howToUsePost as string);
      setSteps(loadedProduct.howToUseSteps as string[]);
      setImage1(loadedProduct.images && loadedProduct.images[0]);
      setImage2(loadedProduct.images && loadedProduct.images[1]);
      setImage3(loadedProduct.images && loadedProduct.images[2]);
      setImage4(loadedProduct.images && loadedProduct.images[3]);
    }
  };

  useEffect(() => {
    dispatch(setActiveTab(SideBarTabs.Products.name));
  }, [setActiveTab]);

  useEffect(() => {
    if (!product) {
      updateStates();
    }
  }, []);

  const add = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price.toString());
    formData.append("stock", amountInStock.toString());
    formData.append("howToUsePre", stepPre);
    formData.append("howToUsePost", stepPost);
    availableSizes.forEach((size, index) => {
      formData.append(`sizes[${index}][sizeName]`, size.name);
      formData.append(`sizes[${index}][price]`, size.price.toString());
    });
    formData.append("availableSizes", sizesAvailable.toString());
    formData.append("type", type);
    formData.append("category", category);
    steps.forEach((step, index) => {
      formData.append(`howToUseSteps[${index}]`, step);
    });
    if (image1 && typeof image1 !== "string") {
      formData.append("image1", image1);
    }
    if (image2 && typeof image2 !== "string") {
      formData.append("image2", image2);
    }
    if (image3 && typeof image3 !== "string") {
      formData.append("image3", image3);
    }
    if (image4 && typeof image4 !== "string") {
      formData.append("image4", image4);
    }
    const updatedProduct = await updateProduct(
      params?.id as string,
      formData,
      setUpdating
    );
    if (updatedProduct) {
      adminNavigate(Paths.dashboardHome);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleAdd = () => {
    if (!name) {
      alert(productNameErrorText);
      return;
    }
    if (!price) {
      alert(priceErrorText);
      return;
    }
    if (!amountInStock) {
      alert(inStockErrorText);
      return;
    }
    if (!type) {
      alert(typeErrorText);
      return;
    }
    if (!category) {
      alert(categoryErrorText);
      return;
    }
    if (!image1) {
      alert(imagesErrorText);
      return;
    }
    if (!image2) {
      alert(imagesErrorText);
      return;
    }
    if (!image3) {
      alert(imagesErrorText);
      return;
    }
    if (!image4) {
      alert(imagesErrorText);
      return;
    }
    add();
  };

  return (
    <div
      className={`${CommonStyles.dashBoardPageWrapper} ${ClassName.container}`}
    >
      <DashboardHeader pageName={titleText} />
      <div className={ClassName.content}>
        <div className={ClassName.leftForm}>
          <UpdateProductImages
            image1={image1}
            image2={image2}
            image3={image3}
            image4={image4}
            setImage1={setImage1}
            setImage2={setImage2}
            setImage3={setImage3}
            setImage4={setImage4}
          />

          <NameContainerForm
            name={name}
            setName={setName}
            price={price}
            setPrice={setPrice}
            setAmountInStock={setAmountInStock}
            amountInStock={amountInStock}
            type={type}
            setType={setType}
            category={category}
            setCategory={setCategory}
            description={description}
            setDescription={setDescription}
          />

          <button className={ClassName.mainButton} onClick={handleAdd}>
            {updateButtonText}
          </button>
        </div>
        <div className={ClassName.rightForm}>
          <AvailableSizesForm
            sizesAvailable={sizesAvailable}
            setSizesAvailable={setSizesAvailable}
            availableSizes={availableSizes}
            setAvailableSizes={setAvailableSizes}
          />

          <StepsForm
            availableSteps={stepsAvailable}
            postSteps={stepPost}
            preSteps={stepPre}
            setPostSteps={setStepPost}
            setPreSteps={setStepPre}
            setSteps={setSteps}
            setStepsAvailable={setStepsAvailable}
            steps={steps}
          />
        </div>
        <button className={ClassName.mobileButton} onClick={handleAdd}>
          {updateButtonText}
        </button>
      </div>
    </div>
  );
}

export default DashboardUpdateProductPage;

const ClassName = {
  container: "",
  content:
    "flex flex-col md:flex-row flex-1  gap-4 mt-4 text-secondaryText text-xs md:text-sm",
  leftForm: "flex flex-col gap-2 flex-1",
  mainButton:
    "bg-dprimary h-12 items-center justify-center rounded-lg text-white text-base font-semibold hidden md:flex",
  mobileButton:
    "bg-dprimary h-12 items-center justify-center rounded-lg text-white text-sm md:text-base font-semibold flex md:hidden",
  rightForm: "flex flex-col flex-1 gap-4",
};
