import { CommonStyles } from "../styles/CommonStyles";
import Header from "../components/common/Header";
import { useEffect, useState } from "react";
import Footer from "../components/common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage, setProgressBarActive } from "../reducers/pageReducer";
import { Paths } from "../router/Paths";
import ContactSection from "../sections/ContactSection";
import ShopHeroSection from "../sections/ShopHeroSection";
import AppCopy from "../copy/AppCopy";
import Product from "../components/shop/Product";
import DownArrowBrown from "../assets/DownArrowBrown.svg";
import { RootState } from "../app/store";
import { useParams } from "react-router-dom";
import { useGetProducts } from "../hook/useGetProducts";
import { ProductType } from "../declarations/productService";
import { CategoryTabInterface, CategoryTabs } from "../constants/tabs";
import ProgressBar from "../components/common/ProgressBar";

function ShopPage() {
  const dispatch = useDispatch();
  const { getProducts, getProductsWithDispatch } = useGetProducts();
  const { category } = useParams();
  const { shop, products } = useSelector((state: RootState) => state.product);

  const [loadedProducts, setLoadedProducts] = useState<ProductType[] | null>(
    category ? products : shop
  );
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const loadProducts = async () => {
    if (!category) {
      if (shop) {
        setLoadedProducts(shop);
        return;
      }
      dispatch(setProgressBarActive(true));
      getProductsWithDispatch({});
      dispatch(setProgressBarActive(false));
    } else {
      if (products) return;
      dispatch(setProgressBarActive(true));
      const data = await getProducts({ category });
      setLoadedProducts(data);
      dispatch(setProgressBarActive(false));
    }
  };

  useEffect(() => {
    dispatch(setActivePage(Paths.shop));
    window.scrollTo(0, 0);
    loadProducts();
  }, [dispatch]);

  useEffect(() => {
    if (category) {
      setLoadedProducts(products);
    } else {
      setLoadedProducts(shop);
    }
  }, [products, shop]);

  useEffect(() => {
    if (category) {
      if (products) {
        setLoadedProducts(products);
      } else {
        loadProducts();
      }
    }
  }, [category]);

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSearch = async (value: string) => {
    dispatch(setProgressBarActive(true));
    const data = await getProducts({
      search: searchValue,
    });
    setLoadedProducts(data);
    dispatch(setProgressBarActive(false));
  };

  const handleLoadMore = async () => {
    const data = await getProducts({
      setLoading: setLoadingMore,
      search: searchValue,
      category: category,
      offset: loadedProducts?.length,
    });
    if (!data) return;
    if (!loadedProducts) return;
    setLoadedProducts([...loadedProducts, ...data]);
  };

  const { allProductsTitleText, loadMoreButtonText } = AppCopy.shop;

  const getProductsTitle = (): string => {
    if (category) {
      let title = Object.values(CategoryTabs).find(
        (value: CategoryTabInterface) => value.name === category
      ).displayName;
      return title;
    }
    return allProductsTitleText;
  };
  return (
    <div className={`${CommonStyles.pageWrapper}`}>
      <ProgressBar />
      <Header />

      <ShopHeroSection
        onSearchValueChange={handleSearchValueChange}
        searchValue={searchValue}
        onSearch={() => handleSearch(searchValue)}
        category={category}
      />
      <div className={ClassName.productsTitle}>{getProductsTitle()}</div>
      <div className={ClassName.productSection}>
        <div className={ClassName.productsContainer}>
          {loadedProducts?.map((product, index: number) => (
            <Product key={index} product={product} />
          ))}
        </div>
        {loadingMore ? (
          <span className={ClassName.loadMoreLoader}></span>
        ) : (
          <button className={ClassName.loadMoreButton} onClick={handleLoadMore}>
            <span>{loadMoreButtonText}</span>
            <img src={DownArrowBrown} alt="" className={ClassName.arrowIcon} />
          </button>
        )}
      </div>
      <ContactSection />
      <Footer />
    </div>
  );
}

export default ShopPage;

const ClassName = {
  productsTitle:
    "w-full text-center uppercase font-primary text-3xl sm:text-4xl lg:text-6xl mb-6 text-quaternary",
  productSection: "w-full flex flex-col items-center justify-center",
  productsContainer:
    "flex flex-row flex-wrap items-center justify-center w-full md:w-90percent max-w-7xl ",
  loadMoreButton:
    "border border-primary text-primary text-base py-3 md:py-4 px-8 md:px-10 flex flex-row items-center mt-20",
  loadMoreLoader: "mt-20 loader",
  arrowIcon: "w-4 h-4 object-contain ml-2",
};
