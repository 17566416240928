import { AxiosResponse } from "axios";
import { getRequest } from "../commonActions/get";
import { Requests } from "../../config/api";
import {
  CreateProductProps,
  CreateProductReturnType,
  DeleteProductProps,
  DeleteProductReturnType,
  GetProductProps,
  GetProductReturnType,
  GetProductsProps,
  GetProductsReturnType,
  UpdateProductProps,
  UpdateProductReturnType,
} from "../../declarations/productService";
import { QueryParamsProps } from "../../declarations/commonServices";
import { GetQueryVariables } from "../apiFunctions";
import { postRequest } from "../commonActions/post";
import { deleteRequest } from "../commonActions/delete";
import { patchRequest } from "../commonActions/update";

export const GetProducts = async ({
  setLoading,
  category,
  limit,
  offset,
  populate,
  search,
  sort,
  type,
}: GetProductsProps): Promise<GetProductsReturnType> => {
  let queryParams: QueryParamsProps = GetQueryVariables({
    category,
    limit,
    offset,
    populate,
    search,
    sort,
    type,
  });

  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await getRequest({
      route: Requests.get.getProducts,
      queryParams,
    });
    setLoading && setLoading(false);

    const productList = response?.data?.products;
    const status = response?.status;
    const message = response?.data?.msg;

    return {
      status: status,
      products: productList,
      message: message,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};

export const GetProduct = async ({
  setLoading,
  id,
}: GetProductProps): Promise<GetProductReturnType> => {
  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await getRequest({
      route: Requests.get.getProduct,
      pathVariables: {
        id,
      },
    });
    setLoading && setLoading(false);

    const products = response?.data?.product;
    const status = response?.status;
    const message = response?.data?.msg;

    return {
      status: status,
      product: products,
      message: message,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};

export const DeleteProduct = async ({
  setLoading,
  id,
}: DeleteProductProps): Promise<DeleteProductReturnType> => {
  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await deleteRequest({
      route: Requests.delete.deleteProduct,
      pathVariables: {
        id,
      },
    });
    setLoading && setLoading(false);

    const products = response?.data?.product;
    const status = response?.status;
    const message = response?.data?.msg;

    return {
      status: status,
      product: products,
      message: message,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};

export const CreateProduct = async ({
  setLoading,
  payload,
}: CreateProductProps): Promise<CreateProductReturnType> => {
  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await postRequest({
      formData: true,
      route: Requests.post.createProduct,
      payload,
    });
    setLoading && setLoading(false);

    const product = response?.data?.product;
    const status = response?.status;
    const message = response?.data?.msg;

    return {
      status: status,
      product: product,
      message: message,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};

export const UpdateProduct = async ({
  setLoading,
  payload,
  id,
}: UpdateProductProps): Promise<UpdateProductReturnType> => {
  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await patchRequest({
      route: Requests.update.updateProduct,
      payload,
      pathVariables: { id },
    });
    setLoading && setLoading(false);

    const product = response?.data?.product;
    const status = response?.status;
    const message = response?.data?.msg;

    return {
      status: status,
      product: product,
      message: message,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};
