import React, { useEffect, useState } from "react";
import { CommonStyles } from "../styles/CommonStyles";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import ContactSection from "../sections/ContactSection";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage, setProgressBarActive } from "../reducers/pageReducer";
import { Paths } from "../router/Paths";
import ProductHeroSection from "../sections/ProductHeroSection";
import ProductDescriptionSection from "../sections/ProductDescriptionSection";
import ProgressBar from "../components/common/ProgressBar";
import { RootState } from "../app/store";
import { useNavigate, useParams } from "react-router-dom";
import { ProductType } from "../declarations/productService";
import { useGetProducts } from "../hook/useGetProducts";
import { setProduct } from "../reducers/productReducer";

function ProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product } = useSelector((state: RootState) => state.product);
  const { id } = useParams();
  const { getProduct } = useGetProducts();

  const [loadedProduct, setLoadedProduct] = useState<ProductType | null>(
    product
  );

  const loadProduct = async () => {
    if (product) {
      setLoadedProduct(product);
      dispatch(setProduct(product));
      return;
    }
    if (!id) {
      navigate(-1);
      return;
    }
    dispatch(setProgressBarActive(true));
    const data = await getProduct(id);
    setLoadedProduct(data);
    dispatch(setProduct(data));
    dispatch(setProgressBarActive(false));
  };

  useEffect(() => {
    dispatch(setActivePage(Paths.product));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    loadProduct();
  }, []);

  return (
    <div className={`${CommonStyles.pageWrapper}`}>
      <ProgressBar />
      <Header />
      <ProductHeroSection product={loadedProduct} />

      <ProductDescriptionSection product={loadedProduct} />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default ProductPage;
