import { useEffect, useState } from "react";
import Logo from "../../assets/ShebaTimeLogo.svg";
import {
  CategoryTabInterface,
  CategoryTabs,
  CommonHeaderTabInterface,
  CommonHeaderTabs,
  HeaderTabCart,
  HeadertabType,
} from "../../constants/tabs";
import Cart from "../../assets/Cart.svg";
import CartActive from "../../assets/CartActive.svg";
import { HeaderProps } from "../../declarations/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setActiveHeaderTab } from "../../reducers/headerReducer";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../router/Paths";
import { useGetProducts } from "../../hook/useGetProducts";
import { setSelectedSection } from "../../reducers/pageReducer";

function Header({ shrinkAnimation }: HeaderProps) {
  const { active } = useSelector((state: RootState) => state.header);
  const { active: activePage } = useSelector((state: RootState) => state.page);
  const { cart } = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getTabProducts } = useGetProducts();

  //states
  const [activeTab, setActiveTab] = useState<HeadertabType | null>(active);
  const [numberOfItemsInCart, setNumberOfItemsInCart] = useState<number>(
    cart.length
  );
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!shrinkAnimation) return;
      if (window.scrollY > window.innerHeight * 0.2) {
        if (!isScrolled) {
          setIsScrolled(true);
        }
      } else {
        if (isScrolled) {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled, shrinkAnimation]);

  useEffect(() => {
    setActiveTab(active);
  }, [active]);

  useEffect(() => {
    setNumberOfItemsInCart(cart.length);
  }, [cart]);

  const handleSelectTab = async (tab: HeadertabType | null) => {
    setActiveTab(tab);
    dispatch(setActiveHeaderTab(tab));
    switch (tab) {
      case CommonHeaderTabs.faqs.name:
        navigate(Paths.home);
        dispatch(setSelectedSection(tab));
        break;
      case CommonHeaderTabs.contactus.name:
        dispatch(setSelectedSection(tab));
        navigate(Paths.home);
        break;
      case HeaderTabCart:
        if (activePage === Paths.cart) {
          window.scrollTo(0, 0);
        } else {
          navigate(Paths.cart);
        }
        break;
      default:
        if (activePage === Paths.shop) {
          window.scrollTo(0, 0);
        }
        const categoryArray = Object.values(CategoryTabs);
        const selectedTab = categoryArray.find((item) => item.name === tab);
        if (selectedTab) {
          await getTabProducts(selectedTab.name);
          navigate(`${Paths.shop}/${tab}`);
        } else {
          navigate(Paths.home);
        }

        break;
    }
  };

  return (
    <div className={ClassName.container}>
      <div className={ClassName.content}>
        <div className={ClassName.headerLeftContent}>
          {Object.values(CategoryTabs).map(
            (tab: CategoryTabInterface, index: number) => (
              <span
                key={index}
                className={`${ClassName.headerTab} ${
                  activeTab === tab.name
                    ? ClassName.headerTabActive
                    : ClassName.headerTabInActive
                }`}
                onClick={(e) => handleSelectTab(tab.name)}
              >
                {tab.displayName}
              </span>
            )
          )}
        </div>
        <img
          src={Logo}
          alt="Sheba time"
          className={`${ClassName.headerLogo} ${
            !isScrolled && shrinkAnimation
              ? "h-8 sm:h-14"
              : isScrolled && shrinkAnimation
              ? "h-8 sm:h-10"
              : "h-8 sm:h-10"
          } `}
          onClick={(e) => handleSelectTab(null)}
        />
        <div className={ClassName.headerRightContent}>
          {Object.values(CommonHeaderTabs).map(
            (tab: CommonHeaderTabInterface, index: number) => (
              <span
                key={index}
                className={`${ClassName.headerTab} ${
                  activeTab === tab.name && ClassName.headerTabActive
                }`}
                onClick={(e) => handleSelectTab(tab.name)}
              >
                {tab.displayName}
              </span>
            )
          )}
          <div
            className={ClassName.headerTabCartContainer}
            onClick={(e) => handleSelectTab(HeaderTabCart)}
          >
            {numberOfItemsInCart > 0 && (
              <div className={ClassName.headerCartNumberContainer}>
                {numberOfItemsInCart}
              </div>
            )}
            <img
              src={activeTab === HeaderTabCart ? CartActive : Cart}
              alt="Cart"
              className={ClassName.headerTabCart}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

const ClassName = {
  container:
    "fixed w-full bg-tertiary flex flex-row items-center justify-center font-secondary z-50",
  content: "percent-width-90 max-w-7xl flex items-center justify-between",
  headerLeftContent: "hidden md:flex flex-1",
  headerRightContent: "flex flex-1 justify-end",
  headerLogo: "contain my-2 transition-all cursor-pointer",
  headerTab:
    "hidden sm:flex text-sm mr-4 xl:mr-11 uppercase cursor-pointer hover:text-primary",
  headerTabInActive: "text-quaternary",
  headerTabActive: "text-primary font-bold",
  headerTabCart: "w-6 h-4 object-contain",
  headerTabCartContainer: "relative cursor-pointer",
  headerCartNumberContainer:
    "bg-primary rounded-full padding-inline-4 text-white font-size-8 absolute position-absolute-top-minus-10 position-absolute-right-minus-5",
};
