import {
  FaqInterface,
  FaqQuestionProps,
} from "../../declarations/homeComponents";
import Plus from "../../assets/Plus.svg";
import Minus from "../../assets/Minus.svg";

function FaqQuestion({ faq, selected, onClick }: FaqQuestionProps) {
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <div className={ClassName.container} onClick={handleClick}>
      <div className={ClassName.content}>
        <img src={selected ? Minus : Plus} alt="" className={ClassName.Icon} />
        <div className={`${selected ? ClassName.activeContent : ""}`}>
          {faq.question}
        </div>
      </div>
      {selected && (
        <div className={ClassName.answerContainer}>
          <div className={ClassName.Icon}></div>
          <div className={ClassName.answer}>{faq.answer}</div>
        </div>
      )}
    </div>
  );
}

export default FaqQuestion;

const ClassName = {
  container:
    "w-full mb-4 flex flex-col bg-white border border-secondary cursor-pointer",
  content:
    "w-full py-2 sm:py-4 md:py-6 border-box px-2 md:px-4 pr-10 text-sm md:text-xl text-secondary flex flex-row jusitfy-start items-center",
  Icon: "h-3 w-3 md:h-6 md:w-6 object-contain mr-4 md:mr-6 ml-2 md:ml-4",
  activeContent: "text-primary",
  answerContainer:
    "text-primary text-sm flex felx-row items-center mb-4 md:mb-6 px-4 pr-10 ",
  answer: "border-t border-secondary pt-4 text-xs md:text-base",
};
