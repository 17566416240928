import { CommonStyles } from "../styles/CommonStyles";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import ProgressBar from "../components/common/ProgressBar";
import LoginSection from "../sections/LoginSection";
import { useAdminNavigate } from "../hook/useAdminNavigate";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useEffect } from "react";
import { Paths } from "../router/Paths";
import { LoadCachedItemSession, SessionCacheItem } from "../config/cache";

function LoginPage() {
  const adminNavigate = useAdminNavigate();
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const path = LoadCachedItemSession(SessionCacheItem.adminPath, true);
    const adminPath = Paths.adminPath;
    const pathWithoutAdmin = path.replace(adminPath, "");

    if (!user) {
    } else {
      if (window.location.pathname === Paths.dashboardLogin) {
        adminNavigate(Paths.dashboardHome1);
      } else {
        adminNavigate(pathWithoutAdmin);
      }
    }
  }, [user]);

  return (
    <div className={`${CommonStyles.pageWrapper}`}>
      <ProgressBar />
      <LoginSection />
      <Header />
      <Footer />
    </div>
  );
}

export default LoginPage;
