import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import {
  CreateProduct,
  DeleteProduct,
  GetProduct,
  GetProducts,
  UpdateProduct,
} from "../api/services/product";
import { GetProps, IdType } from "../declarations/commonServices";
import { setProducts, setShop } from "../reducers/productReducer";
import { CategoryTabsType } from "../constants/tabs";
import { setProgressBarActive } from "../reducers/pageReducer";
import { CreateProductProps } from "../declarations/productService";

export const useGetProducts = () => {
  const dispatch = useDispatch();
  const { products, shop } = useSelector((state: RootState) => state.product);

  const getProducts = async ({
    category,
    limit,
    offset,
    populate,
    search,
    setLoading,
    sort,
    type,
  }: GetProps) => {
    const response = await GetProducts({
      setLoading: setLoading,
      category,
      limit,
      offset,
      populate,
      search,
      sort,
      type,
    });
    const { products, message, status } = response;

    return products;
  };

  const getProductsWithDispatch = async ({
    category,
    limit,
    offset,
    populate,
    search,
    setLoading,
    sort,
    type,
  }: GetProps) => {
    if (!shop || shop.length === 0) {
      const response = await GetProducts({
        setLoading: setLoading,
        category,
        limit,
        offset,
        populate,
        search,
        sort,
        type,
      });
      const { products, message, status } = response;

      if (status === 200) {
        dispatch(setShop(products));
        dispatch(setProducts(products));
      }
    }
  };

  const getProduct = async (
    id: IdType,
    setLoading?: (loading: boolean) => void
  ) => {
    const response = await GetProduct({
      setLoading,
      id,
    });
    const { product, message, status } = response;
    return product;
  };

  const deleteProduct = async (
    id: IdType,
    setLoading?: (loading: boolean) => void
  ) => {
    dispatch(setProgressBarActive(true));
    const response = await DeleteProduct({
      setLoading,
      id,
    });
    const { product, message, status } = response;
    if (status !== 200) {
      alert(message);
    } else {
      window.location.reload();
    }
  };

  const updateProduct = async (
    id: IdType,
    formData: FormData,
    setLoading?: (loading: boolean) => void
  ) => {
    dispatch(setProgressBarActive(true));
    const response = await UpdateProduct({
      setLoading,
      id,
      payload: formData,
    });
    dispatch(setProgressBarActive(false));
    const { product, message, status } = response;

    if (status !== 200) {
      alert(message);
      return;
    }
    return product;
  };

  const getTabProducts = async (tab: CategoryTabsType) => {
    dispatch(setProgressBarActive(true));
    const data = await getProducts({ category: tab });
    dispatch(setProducts(data));
    dispatch(setProgressBarActive(false));
  };

  const createProduct = async ({
    payload,
    setLoading,
  }: CreateProductProps): Promise<number> => {
    dispatch(setProgressBarActive(true));
    const response = await CreateProduct({
      setLoading: setLoading,
      payload: payload,
    });
    const { product, message, status } = response;
    dispatch(setProgressBarActive(false));
    return (status as number) || 0;
  };

  return {
    getProducts,
    getProductsWithDispatch,
    getProduct,
    getTabProducts,
    createProduct,
    deleteProduct,
    updateProduct,
  };
};
