import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeadertabType } from "../constants/tabs";

interface HeaderState {
  active: HeadertabType | null;
}

const initialState: HeaderState = {
  active: null,
};

export const header = createSlice({
  name: "header",
  initialState,
  reducers: {
    setActiveHeaderTab: (
      state,
      action: PayloadAction<HeadertabType | null>
    ) => {
      state.active = action.payload;
    },
  },
});

export const { setActiveHeaderTab } = header.actions;

export default header.reducer;
