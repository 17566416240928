import { BASE_URL } from "./api";

export const SocketURL = (): string => {
  const baseURL = BASE_URL ? BASE_URL.replace("/api/v1", "") : "";
  return baseURL;
};

export const SocketEvents = {
  newOrder: "newOrder",
  Connect: "connect",
};
