import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const ProgressBar = () => {
  const { progressBarActive } = useSelector((state: RootState) => state.page);
  return (
    <div className={`progress-bar ${progressBarActive ? "loading" : ""}`}>
      <div className="filler" />
    </div>
  );
};

export default ProgressBar;
