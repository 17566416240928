import React from "react";
import { CartItemProps } from "../../declarations/CommonComponents";
import Checkbox from "./Checkbox";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../router/Paths";
import AppCopy from "../../copy/AppCopy";
import { getPriceWithCurrency } from "../../functions/currency";
import DeleteButton from "../../assets/DeleteButton.svg";
import PlusButton from "../../assets/PlusButton.svg";
import MinusButton from "../../assets/MinusButton.svg";
import { getTextWithEllipses } from "../../functions/text";
import { useDispatch } from "react-redux";
import { setProduct } from "../../reducers/productReducer";
import { CacheItemSession, SessionCacheItem } from "../../config/cache";
import { ProductTarget } from "../../constants/productType";

function CartItem({
  product,
  selectedAmount,
  selectedSize,
  selected,
  onAdd,
  onSubtract,
  onDelete,
  onItemSelect,
}: CartItemProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAdd = () => {
    onAdd && onAdd();
  };

  const handleSubtract = () => {
    onSubtract && onSubtract();
  };

  const handleDelete = () => {
    onDelete && onDelete();
  };

  const handleImagePress = () => {
    dispatch(setProduct(product.product));
    CacheItemSession(SessionCacheItem.product, product.product);
    navigate(`${Paths.product}/${product.productName}/${product.product.name}`);
  };

  const handleItemSelect = () => {
    onItemSelect && onItemSelect(!selected);
  };

  const productDescription = product?.product?.description;

  const { sizeText } = AppCopy.cart;

  return (
    <div className={ClassName.container}>
      <div className={ClassName.checkboxContainer}>
        <Checkbox selected={selected} onClick={handleItemSelect} />
      </div>
      <div className={ClassName.content}>
        <div className={ClassName.name} onClick={handleItemSelect}>
          {product?.productName}
        </div>
        <div className={ClassName.category}>{product?.product?.category}</div>
        <div className={ClassName.detailContainer}>
          <div
            className={`${ClassName.imageContainer} ${
              product.product.type === ProductTarget.feminine
                ? ClassName.feminine
                : ClassName.masculine
            }`}
            onClick={handleImagePress}
          >
            {product?.product?.images && (
              <img
                src={product?.product.images[0]}
                alt=""
                className={ClassName.image}
              />
            )}
          </div>
          <div className={ClassName.detail}>
            <div className={ClassName.description1}>
              {getTextWithEllipses(productDescription, 300)}
            </div>
            <div className={ClassName.description2}>
              {getTextWithEllipses(productDescription, 250)}
            </div>
            <div className={ClassName.description3}>
              {getTextWithEllipses(productDescription, 200)}
            </div>
            {product.selectedSize && (
              <div>
                {sizeText}: {product.selectedSize}
              </div>
            )}
            <div className={ClassName.detailBottomContainer}>
              <div className={ClassName.price}>
                {getPriceWithCurrency(product?.price)}
              </div>
              <div className={ClassName.bottomButtonsContainer}>
                <div
                  className={ClassName.buttonContainer}
                  onClick={handleDelete}
                >
                  <img src={DeleteButton} alt="" className={ClassName.button} />
                </div>
                <div
                  className={ClassName.buttonContainer}
                  onClick={handleSubtract}
                >
                  <img src={MinusButton} alt="" className={ClassName.button} />
                </div>
                <div>{product.quantity}</div>
                <div className={ClassName.buttonContainer} onClick={handleAdd}>
                  <img src={PlusButton} alt="" className={ClassName.button} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;

const ClassName = {
  container:
    "w-full border-b border-primary pb-4 sm:pb-8 flex flex-row items-start pt-6 sm:pt-10 font-secondary",
  content: "flex flex-col grow w-full",
  checkboxContainer: "grow",
  name: "text-sm md:text-base cursor-pointer",
  category: "text-xs md:text-sm text-quaternary mb-2 md:mb-4",
  detailContainer: "flex flex-col sm:flex-row grow",
  imageContainer:
    "w-full md:w-64 h-52 md:h-64 flex items-center justify-center cursor-pointer",
  feminine: "bg-tertiary",
  masculine: "bg-quinary",
  image: "h-36 w-36 md:h-40 md:w-40 object-contain",
  detail:
    "ml-0 mt-4 sm:mt-0 sm:ml-6 flex flex-col flex-1 text-sm md:text-base items-start items-start justify-between",
  detailBottomContainer:
    "flex flex-row w-full items-center justify-between mt-4 sm:mt-0",
  price: "font-medium text-base md:text-xl",
  bottomButtonsContainer: "flex flex-row items-center",
  buttonContainer: "cursor-pointer px-4",
  button: "h-4 w-4 object-contain",
  description1: "hidden lg:flex mb-4 sm:mb-0",
  description2: " hidden md:flex lg:hidden mb-4 sm:mb-0",
  description3: "flex md:hidden mb-4 sm:mb-0",
};
