import { AxiosResponse } from "axios";
import {
  SendMessageProps,
  SendMessageReturnType,
} from "../../declarations/messageServices";
import { postRequest } from "../commonActions/post";
import { Requests } from "../../config/api";

export const SendMessage = async ({
  contact,
  message,
  name,
  setLoading,
}: SendMessageProps): Promise<SendMessageReturnType> => {
  try {
    setLoading && setLoading(true);
    const response: AxiosResponse = await postRequest({
      route: Requests.post.sendMessage,
      payload: { contact, message, name },
    });
    setLoading && setLoading(false);

    const data = response?.data?.message;
    const status = response?.status;
    const msg = response?.data?.msg;

    return {
      status: status,
      message: msg,
      data: data,
    };
  } catch (error) {
    setLoading && setLoading(false);
    throw error;
  }
};
