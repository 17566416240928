import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useAdminNavigate } from "./useAdminNavigate";
import { Paths } from "../router/Paths";
import { useNavigate } from "react-router-dom";
import CachedItem, {
  LoadCachedItem,
  LoadCachedItemSession,
  SessionCacheItem,
} from "../config/cache";

export function useCheckUser() {
  const navigate = useNavigate();
  const adminNavigate = useAdminNavigate();
  const { token, user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const path = LoadCachedItemSession(SessionCacheItem.adminPath, true);
    const adminPath = Paths.adminPath;
    const pathWithoutAdmin = path.replace(adminPath, "");

    if (!user) {
      navigate(Paths.dashboardLogin);
    } else {
      if (window.location.pathname === Paths.dashboardLogin) {
        adminNavigate(Paths.dashboardHome1);
      } else {
        adminNavigate(pathWithoutAdmin);
      }
    }
  }, [user]);

  return;
}
