import { OrderStatusType } from "../declarations/orderServices";

export const OrderStatuses: OrderStatusType[] = [
  "ordered",
  "assigned",
  "delivered",
  "cancelled",
];

export const OrderStatus: {
  ordered: string;
  assigned: string;
  delivered: string;
  cancelled: string;
} = {
  ordered: "ordered",
  assigned: "assigned",
  delivered: "delivered",
  cancelled: "cancelled",
};
