import { ReactNode } from "react";
import Images from "../Images/Images";
import Overview from "../assets/OverviewTabIcon.svg";
import Products from "../assets/ProductsTabIcon.png";
import Expenses from "../assets/ExpensesTabIcon.svg";
import Revenue from "../assets/RevenueTabIcon.svg";
import DeliveryPeople from "../assets/DeliveryPeopleTabIcon.svg";
import Orders from "../assets/OrdersTabIcon.svg";
import Messages from "../assets/MessagesTabIcon.svg";

export type CategoryTabsType = "men" | "women" | "jewelry" | "baby";
export type CommonHeaderTabsType = "faqs" | "contactus" | "cart";
export type SidebarTabsType =
  | "Overview"
  | "Products"
  | "Expenses"
  | "Revenue"
  | "Delivery People"
  | "Orders"
  | "Messages";

export interface CategoryTabInterface {
  name: CategoryTabsType;
  displayName: string;
  image?: any;
}
export interface CommonHeaderTabInterface {
  name: CommonHeaderTabsType;
  displayName: string;
}

interface CategoryTabsInterface {
  men: CategoryTabInterface;
  jewelry: CategoryTabInterface;
  women: CategoryTabInterface;
  baby: CategoryTabInterface;
}
interface CommonHeaderTabsInterface {
  faqs: CommonHeaderTabInterface;
  contactus: CommonHeaderTabInterface;
}
interface SideBarTabInterface {
  name: SidebarTabsType;
  icon: any;
}

interface SidebarTabsInterface {
  Overview: SideBarTabInterface;
  Products: SideBarTabInterface;
  Expenses: SideBarTabInterface;
  Revenue: SideBarTabInterface;
  DeliveryPeople: SideBarTabInterface;
  Orders: SideBarTabInterface;
  Messages: SideBarTabInterface;
}

export const CategoryTabs: CategoryTabsInterface = {
  men: {
    name: "men",
    displayName: "Men",
    image: Images.categoryMen,
  },
  women: {
    name: "women",
    displayName: "Women",
    image: Images.categoryWomen,
  },
  baby: {
    name: "baby",
    displayName: "Baby Products",
    image: Images.categoryBabyProducts,
  },
  jewelry: {
    name: "jewelry",
    displayName: "Jewelry",
    image: Images.categoryJewelry,
  },
};

export const CommonHeaderTabs: CommonHeaderTabsInterface = {
  faqs: {
    name: "faqs",
    displayName: "FAQs",
  },
  contactus: {
    name: "contactus",
    displayName: "Contact Us",
  },
};

export const SideBarTabs: SidebarTabsInterface = {
  Overview: {
    name: "Overview",
    icon: Overview,
  },
  Products: {
    name: "Products",
    icon: Products,
  },
  Expenses: {
    name: "Expenses",
    icon: Expenses,
  },
  Revenue: {
    name: "Revenue",
    icon: Revenue,
  },
  DeliveryPeople: {
    name: "Delivery People",
    icon: DeliveryPeople,
  },
  Orders: {
    name: "Orders",
    icon: Orders,
  },
  Messages: {
    name: "Messages",
    icon: Messages,
  },
};

export const HeaderTabCart: CommonHeaderTabsType = "cart";

export type HeadertabType = CommonHeaderTabsType | CategoryTabsType;
