import React, { useEffect, useState } from "react";
import { CommonStyles } from "../styles/CommonStyles";
import AppCopy from "../copy/AppCopy";
import DashboardHeader from "../components/common/DashboardHeader";
import Chevron from "../assets/ChevronDown.svg";
import Search from "../assets/SearchIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setActiveTab, setProgressBarActive } from "../reducers/pageReducer";
import { SideBarTabs } from "../constants/tabs";
import Plus from "../assets/PlusIcon.svg";
import { useAdminNavigate } from "../hook/useAdminNavigate";
import { Paths } from "../router/Paths";
import { useGetProducts } from "../hook/useGetProducts";
import DashboardProduct from "../components/shop/DashboardProduct";
import { ProductType } from "../declarations/productService";
import { setProduct, setProducts } from "../reducers/productReducer";

function DashboardProductsPage() {
  const dispatch = useDispatch();
  const navigate = useAdminNavigate();
  const { getProductsWithDispatch, getProducts, deleteProduct } =
    useGetProducts();
  const { products } = useSelector((state: RootState) => state.product);

  const { title, searchButtonText, searchPlaceholder } =
    AppCopy.dashboardProducts;

  //states
  const [searchvalue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>();
  const [loadedProducts, setLoadedProducts] = useState<ProductType[] | null>(
    products
  );

  const loadData = async () => {
    dispatch(setProgressBarActive(true));
    await getProductsWithDispatch({});
    dispatch(setProgressBarActive(false));
  };

  useEffect(() => {
    dispatch(setActiveTab(SideBarTabs.Products.name));
    loadData();
  }, []);

  useEffect(() => {
    setLoadedProducts(products);
  }, [products]);

  const handleSearch = async () => {
    dispatch(setProgressBarActive(true));
    const newProducts = await getProducts({
      search: searchvalue,
    });
    dispatch(setProgressBarActive(false));
    setLoadedProducts(newProducts);
  };

  const onLoadMoreProducts = async () => {
    const newProducts = await getProducts({
      setLoading: setLoading,
      offset: loadedProducts?.length,
      search: searchvalue,
    });
    if (newProducts && loadedProducts) {
      const updatedProducts = [...loadedProducts, ...newProducts];
      if (searchvalue) {
        setLoadedProducts(updatedProducts);
        return;
      }
      dispatch(setProducts(updatedProducts));
    }
  };

  const handleAdd = () => {
    navigate(Paths.dashboardAddProduct);
  };

  const handleEditProduct = (product: ProductType) => {
    dispatch(setProduct(product));
    navigate(`${Paths.dashboardUpdateProduct}${product._id}`);
  };

  const handleDeleteProduct = (product: ProductType) => {
    deleteProduct(product._id);
  };

  const Add = () => {
    return (
      <div onClick={handleAdd} className={ClassName.addContainer}>
        <img src={Plus} alt="" className={ClassName.plus} />
      </div>
    );
  };

  return (
    <div
      className={`${CommonStyles.dashBoardPageWrapper} ${ClassName.container}`}
    >
      <DashboardHeader
        pageName={title}
        sections={[
          <div className={ClassName.searchBarContainer}>
            <div className={ClassName.searchBar}>
              <img src={Search} alt="" className={ClassName.searchIcon} />
              <input
                type="text"
                placeholder={searchPlaceholder}
                value={searchvalue}
                onChange={(e) => setSearchValue(e.target.value)}
                className={ClassName.searchInput}
              />
            </div>
            <button className={ClassName.searchButton} onClick={handleSearch}>
              {searchButtonText}
            </button>
          </div>,
          <Add />,
        ]}
      />
      <div className={ClassName.content}>
        <div className={ClassName.products}>
          {loadedProducts?.map((product, index) => (
            <DashboardProduct
              product={product}
              selected={selected === index}
              onSelect={() => setSelected(index)}
              onEdit={handleEditProduct}
              onDelete={handleDeleteProduct}
            />
          ))}
        </div>
        <div className={ClassName.loadMore} onClick={onLoadMoreProducts}>
          {loading ? (
            <div className={ClassName.loader}></div>
          ) : (
            <img src={Chevron} alt="" className={ClassName.loadMoreIcon} />
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardProductsPage;

const ClassName = {
  container: "pt-20",
  addContainer:
    "w-7 md:w-9 h-7 md:h-9 flex items-center justify-center border-2 border-secondaryText rounded-md cursor-pointer",
  plus: "h-3 md:h-4 w-3 md:w-4 contain ",

  searchBarContainer:
    "flex flex-row items-center h-8 md:h-9 w-70vw md:w-450px text-sm md:text-base",
  searchBar:
    "flex flex-row items-center px-4 bg-white h-full rounded-md flex-1 mr-2 md:mr-4",
  searchIcon: "h-3.5 contain mr-2",
  searchInput: "flex flex-1",
  searchButton:
    "w-fit md:w-100px px-2 md:px-0 h-full rounded-md flex items-center bg-quaternary justify-center text-xs md:text-sm text-white font-semibold cursor-pointer",

  content: "flex flex-col flex-1 md:overflow-scroll scrollbar-card mt-4",
  loadMore:
    "w-full py-6 flex flex-row items-center justify-center cursor-pointer",
  loadMoreIcon: "w-4 contain",
  loader: "loader",
  products: "flex flex-col flex-1 md:pr-4",
};
