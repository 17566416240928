import React, { memo, useEffect } from "react";
import { AvailableSizesFormProps } from "../../declarations/CommonComponents";
import AppCopy from "../../copy/AppCopy";
import Plus from "../../assets/PlusIcon.svg";

function AvailableSizesForm({
  availableSizes,
  setAvailableSizes,
  setSizesAvailable,
  sizesAvailable,
}: AvailableSizesFormProps) {
  const { availableSizesText, priceText, nameText } =
    AppCopy.dashboardAddProduct;

  const CheckSizeInputs = () => {
    if (!availableSizes) return;
    const newAvailableSizes = [...availableSizes];
    let emptyPairs = 0;
    for (let i = 0; i < newAvailableSizes.length; i++) {
      if (
        newAvailableSizes[i].name === "" &&
        newAvailableSizes[i].price === 0
      ) {
        emptyPairs++;
        if (emptyPairs > 1) {
          newAvailableSizes.splice(i, 1);
          i--;
        }
      }
    }
    if (newAvailableSizes.length === availableSizes.length) return;
    setAvailableSizes(newAvailableSizes);
  };

  useEffect(() => {
    CheckSizeInputs();
  }, [availableSizes]);

  const onCheckboxClick = () => {
    setSizesAvailable(!sizesAvailable);
    if (!sizesAvailable) {
      setAvailableSizes([{ name: "", price: 0 }]);
    } else {
      setAvailableSizes([]);
    }
  };

  const handleAdd = () => {
    const newAvailableSizes = [...availableSizes, { name: "", price: 0 }];
    setAvailableSizes(newAvailableSizes);
  };

  return (
    <div className={ClassName.container}>
      <div className={ClassName.titleContainer}>
        <div>{availableSizesText}</div>
        <div className={ClassName.checkbox} onClick={onCheckboxClick}>
          {sizesAvailable && <div className={ClassName.checkBoxFilled}></div>}
        </div>
      </div>
      <div className={ClassName.contentContainer}>
        <div className={ClassName.content}>
          <div className={ClassName.rowContainer}>
            <div className={ClassName.column}>{nameText}</div>
            <div className={ClassName.column}>{priceText}</div>
          </div>
          {availableSizes &&
            availableSizes?.map((size, index) => (
              <div className={ClassName.rowContainer} key={index}>
                <input
                  type="text"
                  placeholder={nameText}
                  className={`${ClassName.column} ${ClassName.input}`}
                  value={size.name}
                  onChange={(event) => {
                    const newAvailableSizes = [...availableSizes];
                    newAvailableSizes[index].name = event.target.value;
                    setAvailableSizes(newAvailableSizes);
                  }}
                />
                <input
                  type="number"
                  placeholder={priceText}
                  className={`${ClassName.column} ${ClassName.input}`}
                  value={size.price}
                  onChange={(event) => {
                    const newAvailableSizes = [...availableSizes];
                    newAvailableSizes[index].price = Number(event.target.value);
                    setAvailableSizes(newAvailableSizes);
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <div className={ClassName.plusContainer}>
        <img src={Plus} alt="" className={ClassName.plus} onClick={handleAdd} />
      </div>
    </div>
  );
}

export default memo(AvailableSizesForm);

const ClassName = {
  container: "flex flex-col h-fit md:h-30vh rounded-xl bg-white px-4",
  titleContainer:
    "flex flex-row w-full py-1 pt-2 border-b border-primaryBorder items-center justify-between font-semibold text-secondaryText",
  checkbox:
    "cursor-pointer border  border-dprimary h-3 w-3 flex items-center justify-center",
  checkBoxFilled: "h-2 w-2 bg-dprimary",
  plusContainer: "w-full flex flex-row items-center justify-end pb-4",
  plus: "cursor-pointer w-3 h-3 object-contain",
  contentContainer: "flex flex-1 flex-col overflow-scroll scrollbar-card pr-4",
  content: "flex flex-col flex-1",
  rowContainer: "w-full flex flex-row gap-4",
  column: "flex-1 my-1",
  input: "w-full h-8 mb-2 mt-1 border border-borderPrimary rounded-md px-4",
};
