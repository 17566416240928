import React from "react";
import { CheckboxProps } from "../../declarations/CommonComponents";

function Checkbox({ onClick, selected }: CheckboxProps) {
  const OnClick = () => {
    onClick && onClick(!selected);
  };

  return (
    <div className={ClassName.container} onClick={OnClick}>
      {selected && <div className={ClassName.content}></div>}
    </div>
  );
}

export default Checkbox;

const ClassName = {
  container:
    "w-3 h-3 md:w-5 md:h-5 border border-primary flex items-center justify-center mr-3 md:mr-6 mt-1 md:mt-0 cursor-pointer",
  content: "bg-primary w-2 h-2 md:w-3.5 md:h-3.5",
};
