import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import {
  CartItemType,
  SelectedForCheckoutType,
} from "../declarations/orderServices";
import { setCart } from "../reducers/orderReducer";
import CachedItem, { CacheItem } from "../config/cache";

export default function () {
  const dispatch = useDispatch();
  const { cart } = useSelector((state: RootState) => state.order);

  const addToCart = ({
    price,
    product,
    productName,
    quantity,
    selectedSize,
  }: CartItemType) => {
    const item = { price, product, productName, quantity, selectedSize };
    const newCart = [...cart, item];
    dispatch(setCart(newCart));
    CacheItem(CachedItem.cart, newCart);
  };

  const removeFromCart = (index: number) => {
    const items = [...cart];
    if (index >= 0 && index < items.length) {
      items.splice(index, 1);
    }
    dispatch(setCart(items));
    CacheItem(CachedItem.cart, items);
  };

  const removeFromSelected = (
    index: number,
    selectedItems: SelectedForCheckoutType[],
    setSelectedItems: (selectedItems: SelectedForCheckoutType[]) => void
  ) => {
    const items = [...selectedItems];
    const updatedItems = items
      .filter((item) => item.index !== index)
      .map((item) => {
        if (item.index >= index) {
          return { ...item, index: item.index - 1 };
        }
        return item;
      });
    setSelectedItems(updatedItems);
  };

  const increaseItemQuantity = (index: number) => {
    const updatedCart = cart.map((item, i) => {
      if (i === index && item.quantity !== undefined) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });

    dispatch(setCart(updatedCart));
  };

  const updateSelectedItems = (
    selectedItems: SelectedForCheckoutType[],
    setSelectedItems: (selectedItems: SelectedForCheckoutType[]) => void
  ) => {
    const updatedSelectedItems: SelectedForCheckoutType[] = selectedItems.map(
      (selectedItem) => {
        if (selectedItem.index >= 0 && selectedItem.index < cart.length) {
          const cartItem = cart[selectedItem.index];

          return {
            ...selectedItem,
            product: cartItem,
          };
        }
        return selectedItem;
      }
    );

    setSelectedItems(updatedSelectedItems);
  };

  const decreaseItemQuantity = (index: number) => {
    const updatedCart = cart.map((item, i) => {
      if (i === index && item.quantity !== undefined && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });

    dispatch(setCart(updatedCart));
  };

  return {
    addToCart,
    removeFromCart,
    increaseItemQuantity,
    decreaseItemQuantity,
    removeFromSelected,
    updateSelectedItems,
  };
}
