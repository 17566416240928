import { CurrencyShort } from "../constants/currency";

export const getPriceWithCurrency = (price: any): string => {
  let priceExtension: string = CurrencyShort;

  if (typeof price === "number") {
    return `${price.toLocaleString()} ${priceExtension}`;
  } else if (typeof price === "string" && !isNaN(Number(price))) {
    const numericPrice = parseInt(price);
    return `${numericPrice.toLocaleString()} ${priceExtension}`;
  } else {
    return "";
  }
};
