import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeadertabType, SideBarTabs, SidebarTabsType } from "../constants/tabs";
import { OrderType } from "../declarations/orderServices";

interface PageState {
  active: string;
  progressBarActive: boolean;
  thankYouModal: boolean;
  phoneNumber: string;
  activeTab: SidebarTabsType;
  selectedSection: HeadertabType | null;
  orderModal: boolean;
  order: OrderType | null;
  sidebarActive: boolean;
}

const initialState: PageState = {
  active: "",
  progressBarActive: false,
  thankYouModal: false,
  phoneNumber: "",
  activeTab: SideBarTabs.Overview.name,
  selectedSection: null,
  orderModal: false,
  order: null,
  sidebarActive: false,
};

export const header = createSlice({
  name: "header",
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<string>) => {
      state.active = action.payload;
    },
    setProgressBarActive: (state, action: PayloadAction<boolean>) => {
      state.progressBarActive = action.payload;
    },
    setThankYouModal: (state, action: PayloadAction<boolean>) => {
      state.thankYouModal = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<SidebarTabsType>) => {
      state.activeTab = action.payload;
    },
    setSelectedSection: (
      state,
      action: PayloadAction<HeadertabType | null>
    ) => {
      state.selectedSection = action.payload;
    },
    setOrderModal: (state, action: PayloadAction<boolean>) => {
      state.orderModal = action.payload;
    },
    setOrder: (state, action: PayloadAction<OrderType>) => {
      state.order = action.payload;
    },
    setSidebarActive: (state, action: PayloadAction<boolean>) => {
      state.sidebarActive = action.payload;
    },
  },
});

export const {
  setActivePage,
  setProgressBarActive,
  setThankYouModal,
  setPhoneNumber,
  setActiveTab,
  setOrder,
  setOrderModal,
  setSidebarActive,
  setSelectedSection,
} = header.actions;

export default header.reducer;
