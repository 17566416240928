import React from "react";
import Section from "../components/common/Section";
import LoginForm from "../components/form/LoginForm";

function LoginSection() {
  return (
    <Section classname={ClassName.container}>
      <LoginForm />
    </Section>
  );
}

export default LoginSection;

const ClassName = {
  container: "flex items-center justify-center",
};
