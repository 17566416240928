export const getTextWithEllipses = (text: string, cap: number): string => {
  let updatedText = text;

  if (updatedText.length > cap) {
    updatedText = updatedText.slice(0, cap) + " ...";
  }

  return updatedText;
};

export const Capitalize = (text?: any): string => {
  if (typeof text === "string") {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    return "";
  }
};
