import { OrderModalProps } from "../../declarations/CommonComponents";
import Modal from "react-modal";
import AppCopy from "../../copy/AppCopy";
import PhoneIcon from "../../assets/Phoneicon.svg";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  setOrderModal,
  setProgressBarActive,
} from "../../reducers/pageReducer";
import { useEffect, useState } from "react";
import { Capitalize } from "../../functions/text";
import Chevron from "../../assets/ChevronDown.svg";
import { getPriceWithCurrency } from "../../functions/currency";
import { OrderStatusType } from "../../declarations/orderServices";
import { OrderStatuses } from "../../config/status";
import useOrder from "../../hook/useOrder";
import { CacheItemSession, SessionCacheItem } from "../../config/cache";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  },
  zIndex: 9999,
};

function OrderModal({}: OrderModalProps) {
  const dispatch = useDispatch();
  const { updateOrder } = useOrder();
  const { orderModal, order } = useSelector((state: RootState) => state.page);

  //states
  const [address, setAddress] = useState<string | undefined>(
    order?.deliveryAddress
  );
  const [status, setStatus] = useState<OrderStatusType | undefined>(
    order?.status
  );
  const [delivery, setDelivery] = useState<string>("");
  const [dropdown, setDropdown] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setTimeout(() => {
        if (dropdown) {
          setDropdown("");
        }
      }, 100);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  useEffect(() => {
    setStatus(order?.status);
    setAddress(order?.deliveryAddress);
  }, [order]);

  const OnCloseModal = () => {
    dispatch(setOrderModal(false));
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    order?.products.forEach((product) => {
      totalPrice += product.price * product.quantity;
    });
    return totalPrice;
  };

  const onStatusDropdown = () => {
    setDropdown("status");
  };

  const onStatusSelect = (orderStatus: OrderStatusType) => {
    setStatus(orderStatus);
    setDropdown("");
  };

  const handleUpdate = async () => {
    if (!order?._id) return;
    if (updating) return;

    dispatch(setProgressBarActive(true));
    const response = await updateOrder({
      setLoading: setUpdating,
      id: order?._id,
      status,
      address,
    });
    dispatch(setProgressBarActive(false));

    if (response === 200) {
      window.location.reload();
      CacheItemSession(
        SessionCacheItem.adminPath,
        window.location.pathname,
        true
      );
    }
  };

  const {
    deliveryAddressText,
    deliveryManText,
    priceText,
    productNameText,
    quantityText,
    selectedSizeText,
    statusText,
    totalPriceText,
    updateButtonText,
  } = AppCopy.modal.orderModal;
  return (
    <Modal
      isOpen={orderModal}
      onRequestClose={OnCloseModal}
      style={customStyles}
      contentLabel="Order"
      shouldCloseOnOverlayClick={false}
    >
      <div className={ClassName.container}>
        <div className={ClassName.header}>
          <div className={ClassName.headerLeft}>
            <img src={PhoneIcon} className={ClassName.phoneIcon} alt="" />
            <div>{order?.phoneNumber}</div>
          </div>
          <img
            src={CloseIcon}
            className={ClassName.closeIcon}
            alt=""
            onClick={OnCloseModal}
          />
        </div>

        <div className={ClassName.inputContainer}>
          <div>{deliveryAddressText}</div>
          <input
            type="text"
            className={ClassName.input}
            placeholder={deliveryAddressText}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className={ClassName.dropdowns}>
          <div className={ClassName.inputContainer}>
            <div>{statusText}</div>
            <div
              className={`${ClassName.input} ${ClassName.selectorInput}`}
              onClick={onStatusDropdown}
            >
              <div>{Capitalize(status)}</div>
              <img src={Chevron} alt="" className={ClassName.chevron} />
            </div>
            {dropdown === "status" && (
              <div className={ClassName.dropdown}>
                {OrderStatuses.map((orderStatus, index) => (
                  <div
                    key={index}
                    className={ClassName.dropdownSelectable}
                    onClick={() => onStatusSelect(orderStatus)}
                  >
                    {Capitalize(orderStatus)}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={ClassName.inputContainer}>
            <div>{deliveryManText}</div>
            <div className={`${ClassName.input} ${ClassName.selectorInput}`}>
              <div>{delivery}</div>
              <img src={Chevron} alt="" className={ClassName.chevron} />
            </div>
          </div>
          <div></div>
        </div>

        <div className={ClassName.priceContainer}>
          <span>{totalPriceText}</span>
          <div className={ClassName.totalPrice}>
            {getPriceWithCurrency(getTotalPrice())}
          </div>
        </div>

        <button className={ClassName.updateButton} onClick={handleUpdate}>
          {updateButtonText}
        </button>

        <div className={ClassName.productsHeaderContainer}>
          <div className={ClassName.productsHeader}>
            <div className={ClassName.productName}>{productNameText}</div>
            <div className={ClassName.quantity}>{quantityText}</div>
            <div className={ClassName.selectedSize}>{selectedSizeText}</div>
            <div className={ClassName.price}>{priceText}</div>
          </div>
        </div>

        <div className={ClassName.productsContainer}>
          <div className={ClassName.products}>
            {order?.products.map((product, index) => (
              <div className={ClassName.product}>
                <div className={ClassName.productName}>
                  {product.productName}
                </div>
                <div className={ClassName.quantity}>{product.quantity}</div>
                <div className={ClassName.selectedSize}>
                  {product.selectedSize}
                </div>
                <div className={ClassName.price}>
                  {getPriceWithCurrency(product.price)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OrderModal;

const ClassName = {
  container:
    "bg-white rounded-xl px-4 lg:px-8 py-4 w-95vw sm:w-90vw max-w-920px h-80vh flex flex-col",
  header:
    "flex flex-row items-center justify-between w-full text-base lg:text-xl font-medium text-dprimary mb-2",
  phoneIcon: "w-4 lg:w-5 h-4 lg:h-5 contain mr-2 mt-1",
  headerLeft: "flex flex-row items-center",
  closeIcon: "w-6 lg:w-8 h-6 lg:h-8 contain cursor-pointer",
  inputContainer: "text-secondaryText text-xs lg:text-sm mb-1 lg:mb-2",
  input:
    "text-dprimary h-8 w-64 shrink-0 rounded-md border border-borderPrimary flex flex-row items-center px-3 mt-1 mb-2",
  dropdowns: "flex flex-col lg:flex-row justify-between relative",
  dropdown:
    "w-64 max-h-40 top-60 p-3 overflow-scroll absolute border border-borderPrimary bg-white rounded-md",
  dropdownSelectable: "text-sm text-secondaryText cursor-pointer py-1 w-full",

  selectorInput: "flex flex-row justify-between cursor-pointer",
  chevron: "w-2 contain ml-2",

  priceContainer: "text-secondaryText text-xs sm:text-sm flex-col",
  totalPrice: "font-semibold text-base sm:text-xl text-dprimary",
  updateButton:
    "bg-dprimary h-7 lg:h-9 w-28 lg:w-40 shrink-0 flex items-center justify-center text-white text-sm lg:text-base font-semibold rounded-md mt-2 lg:mt-4",
  productsHeaderContainer: "w-full pr-4 mt-4 text-sm text-secondaryText",
  productsHeader:
    "flex-1 border-b border-borderPrimary hidden lg:flex flex-row items-center justify-between py-2",
  productName: "w-40percent",
  quantity: "w-10percent",
  selectedSize: "w-20percent",
  price: "w-20percent",
  productsContainer:
    "overflow-scroll w-full pr-4 text-xs lg:text-sm text-secondaryText",
  products: "",
  product:
    "flex-1 border-b border-borderPrimary flex flex-row items-center justify-between py-2",
};
