import { CommonStyles } from "../styles/CommonStyles";
import Header from "../components/common/Header";
import { useEffect, useState } from "react";
import Footer from "../components/common/Footer";
import { useDispatch } from "react-redux";
import { setActivePage } from "../reducers/pageReducer";
import { Paths } from "../router/Paths";
import CartSection from "../sections/CartSection";
import ContactSection from "../sections/ContactSection";
import ThankyouModal from "../components/modal/ThankyouModal";
import ProgressBar from "../components/common/ProgressBar";

function CartPage() {
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    dispatch(setActivePage(Paths.cart));
    window.scrollTo(0, 0);
  }, [dispatch]);

  const openModal = () => {
    setIsOpen(true);
  };

  const handleCheckout = () => {
    openModal();
  };
  return (
    <div className={`${CommonStyles.pageWrapper}`}>
      <ProgressBar />
      <Header />
      <ThankyouModal />
      <CartSection onCheckout={handleCheckout} />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default CartPage;
