import Images from "../Images/Images";
import Section from "../components/common/Section";
import AppCopy from "../copy/AppCopy";
import { ShopHeroSectionProps } from "../declarations/sections";

function ShopHeroSection({
  category,
  onSearch,
  onSearchValueChange,
  searchValue,
}: ShopHeroSectionProps) {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchValueChange && onSearchValueChange(e.target.value);
  };

  const handleOnSearch = () => {
    onSearch && onSearch();
  };

  const { heroSubmitButtonText, heroTitleText } = AppCopy.shop;
  return (
    <Section>
      {!category ? (
        <div className={ClassName.container}>
          <div className={ClassName.content}>
            <div className={ClassName.contentLeft}>
              <div className={ClassName.heroTitle}>{heroTitleText}</div>
              <div className={ClassName.inputContainer}>
                <input
                  type="text"
                  value={searchValue}
                  onChange={handleOnChange}
                  className={ClassName.input}
                />
                <button
                  className={ClassName.submitButton}
                  onClick={handleOnSearch}
                >
                  {heroSubmitButtonText}
                </button>
              </div>
            </div>
            <div className={ClassName.contentRight}>
              <img
                src={Images.shopHeroImage}
                alt="hero"
                className={ClassName.heroImage}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={ClassName.categoryInput}>
          <div className={ClassName.inputContainer}>
            <input
              type="text"
              value={searchValue}
              onChange={handleOnChange}
              className={ClassName.categoryInputInput}
            />
            <button className={ClassName.submitButton} onClick={handleOnSearch}>
              {heroSubmitButtonText}
            </button>
          </div>
        </div>
      )}
    </Section>
  );
}

export default ShopHeroSection;

const ClassName = {
  container:
    "w-full bg-tertiary flex items-center justify-center h-60 sm:h-72 md:h-96 my-24 font-secondary",
  categoryInput: "mt-20 bg-tertiary w-full md:w-1/2 mb-6 pl-4",
  categoryInputInput:
    "w-full h-10 sm:h-14 bg-tertiary flex flex-row items-center ",
  content: "flex flex-row items-center w-10/12",
  contentLeft: "flex flex-col w-full md:w-1/2 items-start justify-center",
  heroTitle: "text-3xl sm:text-4xl lg:text-5xl font-primary mb-6",
  inputContainer: "w-full h-10 sm:h-14 bg-white flex flex-row items-center ",
  input: "h-full w-full px-6 text-primary text-sm",
  submitButton: "button-primary bg-black border-box h-full",
  contentRight:
    "w-1/2 h-full p-10 border-box hidden md:flex items-center justify-center ",
  heroImage: "w-10/12 h-10/12 object-contain",
};
