import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "../declarations/userServices";

interface UserState {
  user: UserType | null;
  token: string | null;
}

const initialState: UserState = {
  token: "",
  user: null,
};

export const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
  },
});

export const { setToken, setUser } = product.actions;

export default product.reducer;
