import React, { useState, useEffect } from "react";
import AppCopy from "../../copy/AppCopy";
import { CommonStyles } from "../../styles/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setLoginError } from "../../reducers/errorReducer";
import { useUser } from "../../hook/useUser";

function LoginForm() {
  const dispatch = useDispatch();
  const { login } = useUser();
  const { loginError } = useSelector((state: RootState) => state.error);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  useEffect(() => {
    setUsernameError("");
    setPasswordError("");
    dispatch(setLoginError(""));
  }, [username, password]);

  const {
    formTitle,
    passwordPlaceholder,
    loginButton,
    userNamePlaceholder,
    passwordErrorText,
    usernameErrorText,
  } = AppCopy.loginForm;

  const handleLogin = () => {
    if (!username) {
      setUsernameError(usernameErrorText);
      return;
    }
    if (!password) {
      setPasswordError(passwordErrorText);
      return;
    }
    login({ username, password });
  };

  return (
    <div className={ClassName.container}>
      <span className={ClassName.title}>{formTitle}</span>
      <input
        className={`${CommonStyles.loginInput} ${ClassName.input}`}
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder={userNamePlaceholder}
      />
      {usernameError && (
        <span className={ClassName.error}>{usernameError}</span>
      )}
      <input
        className={`${CommonStyles.loginInput} ${ClassName.input}`}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder={passwordPlaceholder}
      />
      {passwordError && (
        <span className={ClassName.error}>{passwordError}</span>
      )}
      {loginError && <span className={ClassName.error}>{loginError}</span>}
      <button className={ClassName.button} onClick={handleLogin}>
        {loginButton}
      </button>
    </div>
  );
}

export default LoginForm;

const ClassName = {
  container:
    "mt-40 bg-tertiary py-8 sm:py-10 px-5 sm:px-10 flex flex-col w-95percent sm:w-500px ",
  title: "text-xl sm:text-3xl text-primary mb-2 sm:mb-4 font-semibold",
  input: "mt-3 sm:mt-4",
  button: "button-primary w-full mt-5 sm:mt-8",
  error: "text-xs mt-2 text-quaternary ",
};
