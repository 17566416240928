import React from "react";
import { NameContainerFormProps } from "../../declarations/CommonComponents";
import AppCopy from "../../copy/AppCopy";
import { Capitalize } from "../../functions/text";
import { ProductTarget } from "../../constants/productType";
import { CategoryTabs } from "../../constants/tabs";

function NameContainerForm({
  amountInStock,
  category,
  description,
  name,
  price,
  setAmountInStock,
  setCategory,
  setDescription,
  setName,
  setPrice,
  setType,
  type,
}: NameContainerFormProps) {
  const { categoryText, descriptionText, productName, typeText } =
    AppCopy.dashboardAddProduct;

  return (
    <div className={ClassName.nameContainer}>
      <label htmlFor="productName">{productName}</label>
      <input
        type="text"
        id="productName"
        className={ClassName.input}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className={ClassName.priceContainer}>
        <div className={ClassName.priceInputContainer}>
          <label htmlFor="productPrice">
            {AppCopy.dashboardAddProduct.priceText}
          </label>
          <input
            type="number"
            id="productPrice"
            className={ClassName.input}
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
          />
        </div>

        <div className={ClassName.priceInputContainer}>
          <label htmlFor="productInStock">
            {AppCopy.dashboardAddProduct.inStockText}
          </label>
          <input
            type="number"
            id="productInStock"
            className={ClassName.input}
            value={amountInStock}
            onChange={(e) => setAmountInStock(Number(e.target.value))}
          />
        </div>
      </div>
      <div className={ClassName.radioContainer}>
        <div className={ClassName.radios}>
          <div>{typeText}</div>
          {Object.values(ProductTarget).map((productType, index) => (
            <div key={index} className={ClassName.radio}>
              <input
                type="radio"
                id={productType}
                name="type"
                value={type}
                checked={type === productType}
                className={ClassName.radioIcon}
                onChange={(e) => setType(productType)}
              />
              <label htmlFor={productType}>{Capitalize(productType)}</label>
            </div>
          ))}
        </div>
        <div className={ClassName.radios}>
          <div>{categoryText}</div>
          {Object.values(CategoryTabs).map((categoryType, index) => (
            <div key={index} className={ClassName.radio}>
              <input
                type="radio"
                id={categoryType.name}
                name="category"
                value={category}
                checked={category === categoryType.name}
                className={ClassName.radioIcon}
                onChange={(e) => setCategory(categoryType.name)}
              />
              <label htmlFor={categoryType.name}>
                {Capitalize(categoryType.displayName)}
              </label>
            </div>
          ))}
        </div>
      </div>
      <label htmlFor="productDescription">{descriptionText}</label>
      <textarea
        id="productDescription"
        className={ClassName.textArea}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </div>
  );
}

export default NameContainerForm;

const ClassName = {
  nameContainer: "w-full flex-1 flex flex-col rounded-xl p-4 bg-white",
  priceContainer:
    "w-full flex flex-col md:flex-row justify-between gap-0 md:gap-4",
  input: "w-full h-8 mb-2 mt-1 border border-borderPrimary rounded-md px-4",
  priceInputContainer: "flex-1",
  radioContainer: "flex flex-row gap-6 mb-2",
  radios: "flex flex-col cursor-pointer",
  radio: "flex flex-row items-center mt-1 cursor-pointer",
  radioIcon: "mr-2 cursor-pointer",
  textArea:
    "w-full h-8 flex-1 mb-2 mt-1 border border-borderPrimary rounded-md px-4 py-2",
};
