import React, { HTMLInputTypeAttribute, useEffect, useState } from "react";
import Section from "../components/common/Section";
import AppCopy from "../copy/AppCopy";
import Checkbox from "../components/cart/Checkbox";
import CartItem from "../components/cart/CartItem";
import { getPriceWithCurrency } from "../functions/currency";
import { CartSectionProps } from "../declarations/sections";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import useCart from "../hook/useCart";
import {
  CartItemType,
  SelectedForCheckoutType,
} from "../declarations/orderServices";
import useOrder from "../hook/useOrder";

function CartSection({ onCheckout }: CartSectionProps) {
  const {
    removeFromCart,
    removeFromSelected,
    increaseItemQuantity,
    decreaseItemQuantity,
    updateSelectedItems,
  } = useCart();
  const { checkout } = useOrder();
  const { cart } = useSelector((state: RootState) => state.order);
  const { phoneNumber } = useSelector((state: RootState) => state.page);

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<
    Array<SelectedForCheckoutType>
  >([]);
  const [items, setItems] = useState<Array<any>>(cart);
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string>(phoneNumber || "");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (phoneNumber) {
      setPhone(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    updateSelectedItems(selectedItems, setSelectedItems);
  }, [cart]);

  const calculatePrice = () => {
    if (!selectedItems || selectedItems.length === 0) {
      return 0;
    }

    const totalPrice = selectedItems.reduce((accumulator, selectedItem) => {
      const item = selectedItem.product;
      const itemPrice = item?.product?.price || 0;
      const selectedAmount = item.quantity || 0;
      const itemTotalPrice = itemPrice * selectedAmount;
      return accumulator + itemTotalPrice;
    }, 0);

    return totalPrice;
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allItems = items?.map((product, index) => {
        return {
          index,
          product,
        };
      });
      setSelectedItems(allItems);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectOne = (index: number, product: any) => {
    const findProduct = selectedItems?.find((item) => item?.index === index);
    if (findProduct) {
      const updatedProduct = selectedItems?.filter(
        (item) => item?.index !== index
      );
      setSelectedItems(updatedProduct);
      if (selectAll) {
        setSelectAll(false);
      }
    } else {
      let updatedList = [
        ...selectedItems,
        {
          index,
          product: product,
        },
      ];
      if (updatedList?.length === items?.length) {
        handleSelectAll();
      }
      setSelectedItems(updatedList);
    }
  };

  const handleDelete = (index: number) => {
    removeFromCart(index);
    removeFromSelected(index, selectedItems, setSelectedItems);
  };

  const handleAdd = (index: number) => {
    increaseItemQuantity(index);
  };

  const handleSubtract = (index: number) => {
    decreaseItemQuantity(index);
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
    if (error) {
      setError("");
    }
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    if (error) {
      setError("");
    }
  };
  const checkingOut = () => {
    checkout({
      products: selectedItems,
      phoneNumber: phone,
      deliveryAddress: address,
    });
    // onCheckout();
  };

  const {
    cartPageTitleText,
    chooseProductsTitleText,
    emptyCartText,
    orderSummaryText,
    orderTotalText,
    checkoutButtonText,
    deliverAddressInputTitleText,
    phoneNumberInputTitleText,
    phoneNumberPlaceholder,
    invalidPhoneErrorText,
    noDeliveryAddressErrorText,
    noPhoneNumberErrorText,
    zeroSelectedItemsErrorText,
  } = AppCopy.cart;

  const handleCheckout = () => {
    if (selectedItems?.length === 0 || !selectedItems) {
      setError(zeroSelectedItemsErrorText);
      return;
    }
    if (!address) {
      setError(noDeliveryAddressErrorText);
      return;
    }
    if (!phone) {
      setError(noPhoneNumberErrorText);
      return;
    }
    if (phone.length < 10) {
      setError(invalidPhoneErrorText);
      return;
    }
    checkingOut();
  };

  return (
    <Section classname={ClassName.container}>
      <div className={ClassName.content}>
        <div className={ClassName.title}>{cartPageTitleText}</div>
        {cart?.length > 0 ? (
          <div className={ClassName.detailContainer}>
            <div className={ClassName.cartItemsContainer}>
              <div
                className={ClassName.cartItemsTitleContainer}
                onClick={handleSelectAll}
              >
                <Checkbox selected={selectAll} />
                <div>{chooseProductsTitleText}</div>
              </div>
              {cart.map((item: CartItemType, index: number) => (
                <CartItem
                  key={index}
                  product={item}
                  selected={
                    (selectedItems.find((item) => item?.index === index)
                      ? true
                      : false) || selectAll
                  }
                  selectedAmount={item.quantity}
                  selectedSize={item.selectedSize}
                  onItemSelect={() => handleSelectOne(index, item)}
                  onDelete={() => handleDelete(index)}
                  onAdd={() => handleAdd(index)}
                  onSubtract={() => handleSubtract(index)}
                />
              ))}
            </div>

            <div className={ClassName.orderSummaryContainer}>
              <div className={ClassName.orderSummaryTop}>
                <div className={ClassName.orderSummaryTitle}>
                  {orderSummaryText}
                </div>
                {selectedItems?.map((item, index) => (
                  <div key={index} className={ClassName.orderSummaryItem}>
                    <div className={ClassName.orderSummaryItemLeft}>
                      <div className={ClassName.orderSummaryItemLeftAmount}>
                        x{item?.product?.quantity}
                      </div>
                      <div>{item?.product?.productName}</div>
                    </div>
                    <div className={ClassName.orderSummaryItemPrice}>
                      {getPriceWithCurrency(item?.product?.price)}
                    </div>
                  </div>
                ))}
              </div>

              <div className={ClassName.orderTotalContainer}>
                <div className={ClassName.orderTotalLeft}>{orderTotalText}</div>
                <div className={ClassName.orderTotalRight}>
                  {getPriceWithCurrency(calculatePrice())}
                </div>
              </div>

              <div className={ClassName.orderSummaryFormContainer}>
                <div className={ClassName.orderInputContainer}>
                  <div className={ClassName.orderInputLabel}>
                    {deliverAddressInputTitleText}
                  </div>
                  <input
                    type="text"
                    className={ClassName.orderInput}
                    value={address}
                    onChange={onAddressChange}
                  />
                </div>
                <div className={ClassName.orderInputContainer}>
                  <div className={ClassName.orderInputLabel}>
                    {phoneNumberInputTitleText}
                  </div>
                  <input
                    type="text"
                    className={ClassName.orderInput}
                    placeholder={phoneNumberPlaceholder}
                    maxLength={10}
                    value={phone}
                    onChange={onPhoneChange}
                  />
                </div>
                {error && <div className={ClassName.error}>{error}</div>}
                <button
                  className={ClassName.checkoutButton}
                  onClick={handleCheckout}
                >
                  {checkoutButtonText}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={ClassName.noItems}>{emptyCartText}</div>
        )}
      </div>
    </Section>
  );
}

export default CartSection;

const ClassName = {
  container: "mt-20 font-secondary",
  content: "w-full flex flex-col justify-start items-start",
  title: "font-primary text-3xl md:text-5xl mb-8",
  detailContainer: "flex flex-col lg:flex-row items-start w-full",
  cartItemsContainer: "flex flex-col grow",
  cartItemsTitleContainer:
    "w-full flex flex-row border-b border-primary items-start text-sm md:text-base cursor-pointer pb-4",
  noItems: "text-quaternary",

  orderSummaryContainer:
    "w-full lg:max-w-400px py-6 border border-primary lg:ml-20 mt-20 lg:mt-0",
  orderSummaryTop: "w-full border-b pb-4 sm:pb-10 px-4 border-primary",
  orderSummaryTitle: "text-sm sm:text-base font-semibold mb-4",
  orderSummaryItem:
    "w-full flex flex-row items-start justify-between text-xs sm:text-sm text-quaternary mb-2",
  orderSummaryItemLeft: "flex flex-row",
  orderSummaryItemLeftAmount: "w-8",
  orderSummaryItemPrice: "ml-4 grow text-end",
  orderTotalContainer:
    "w-full border-b py-4 px-4 border-primary flex flex-row items-center justify-between text-sm sm:text-base font-semibold",
  orderTotalLeft: "",
  orderTotalRight: "ml-4 grow text-end text-quaternary",
  orderSummaryFormContainer: "flex flex-col w-full pt-4 px-4",
  orderInputContainer: "w-full flex flex-col mb-4",
  orderInputLabel: "mb-2 text-sm sm:text-base",
  orderInput:
    "w-full h-12 px-4 border border-primary text-xs sm:text-sm text-primary",
  checkoutButton: "w-full button-primary mt-6",
  error: "text-quaternary text-xs w-full text-center",
};
