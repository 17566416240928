import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import headerReducer from "../reducers/headerReducer";
import pageReducer from "../reducers/pageReducer";
import productReducer from "../reducers/productReducer";
import orderReducer from "../reducers/orderReducer";
import userReducer from "../reducers/userReducer";
import errorReducer from "../reducers/errorReducer";
import statsReducer from "../reducers/statsReducer";

export const store = configureStore({
  reducer: {
    header: headerReducer,
    page: pageReducer,
    product: productReducer,
    order: orderReducer,
    error: errorReducer,
    user: userReducer,
    stats: statsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
