import { useDispatch } from "react-redux";
import { LoginSubmitProps } from "../declarations/userServices";
import { setProgressBarActive } from "../reducers/pageReducer";
import { Login } from "../api/services/user";
import { setToken, setUser } from "../reducers/userReducer";
import { setLoginError } from "../reducers/errorReducer";
import CachedItem, { CacheItem } from "../config/cache";

export function useUser() {
  const dispatch = useDispatch();
  const login = async ({ password, username }: LoginSubmitProps) => {
    dispatch(setProgressBarActive(true));
    const response = await Login({ password, username });
    const { message, status, token, user } = response;
    if (status === 200) {
      token && dispatch(setToken(token));
      if (token) {
        dispatch(setToken(token));
        CacheItem(CachedItem.token, token, true);
      }
      if (user) {
        dispatch(setUser(user));
        CacheItem(CachedItem.user, user);
      }
    } else {
      message && dispatch(setLoginError(message));
    }
    dispatch(setProgressBarActive(false));
  };

  const logout = () => {
    dispatch(setToken(""));
    dispatch(setUser(null));
    localStorage.removeItem(CachedItem.token);
    localStorage.removeItem(CachedItem.user);
  };

  return {
    login,
    logout,
  };
}
