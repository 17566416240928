import React from "react";
import Section from "../components/common/Section";
import AppCopy from "../copy/AppCopy";
import { ProductDescriptionSectionProps } from "../declarations/sections";

function ProductDescriptionSection({
  product,
}: ProductDescriptionSectionProps) {
  const { descriptionTitleText, howToTitleText } = AppCopy.product;

  return (
    <Section classname={ClassName.container}>
      {!product?.howToUsePre &&
      !product?.howToUsePre &&
      !product?.howToUseSteps ? (
        <div className={ClassName.contentWithNoHowTo}>
          <div
            className={`${ClassName.title} ${ClassName.contentWithNoHowToTitle}`}
          >
            {descriptionTitleText}
          </div>
          {product?.description && (
            <p
              className={`${ClassName.detail}`}
              dangerouslySetInnerHTML={{ __html: product?.description }}
            ></p>
          )}
        </div>
      ) : (
        <div className={ClassName.content}>
          <div className={ClassName.contentLeft}>
            <div className={ClassName.title}>{descriptionTitleText}</div>
            {product?.description && (
              <p
                className={`${ClassName.detail}`}
                dangerouslySetInnerHTML={{ __html: product?.description }}
              ></p>
            )}
          </div>
          <div className={ClassName.contentRight}>
            <div className={ClassName.title}>{howToTitleText}</div>
            <p className={ClassName.detail}>
              <p className={ClassName.preList}>{product.howToUsePre}</p>
              <ol className={ClassName.orderedList}>
                {product.howToUseSteps?.map((step: string, index: number) => (
                  <li key={index} className={ClassName.listItem}>
                    {step}
                  </li>
                ))}
              </ol>
              <p className={ClassName.postList}>{product.howToUsePost}</p>
            </p>
          </div>
        </div>
      )}
    </Section>
  );
}

export default ProductDescriptionSection;

const ClassName = {
  container: "w-full flex items-center justify-between font-secondary py-20",
  content: "w-10/12 flex flex-col md:flex-row items-start justify-between",
  contentLeft: "flex flex-col items-start border-box pr-20 w-full md:w-1/2",
  contentRight: "flex flex-col items-start w-full md:w-1/2 mt-10 md:mt-0",
  title: "text-sm md:text-base font-semibold pb-2 border-b border-primary mb-8",
  detail: "text-xs md:text-sm",
  orderedList: "list-decimal pl-4",
  listItem: "mb-2",
  preList: "mb-4",
  postList: "mt-4",
  contentWithNoHowTo: "flex items-center flex-col max-width-450px",
  contentWithNoHowToTitle: "w-fit",
};
