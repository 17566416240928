import Section from "../components/common/Section";
import AppCopy from "../copy/AppCopy";

function HomeAboutUsSection() {
  const { aboutUsSectionText } = AppCopy.home;
  return (
    <Section classname={ClassName.container}>
      <div className={ClassName.content}>{aboutUsSectionText}</div>
    </Section>
  );
}

export default HomeAboutUsSection;

const ClassName = {
  container:
    "text-primary font-primary uppercase w-full flex items-center justify-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl text-center my-28 md:my-52",
  content: "w-90percent sm:w-86percent leading-tight",
};
