import Section from "./Section";
import Logo from "../../assets/ShebaTimeLogo.svg";
import AppCopy from "../../copy/AppCopy";
import Contact from "../../constants/contact";
import {
  CategoryTabInterface,
  CategoryTabs,
  CategoryTabsType,
  HeadertabType,
} from "../../constants/tabs";
import Instagram from "../../assets/InstagramIcon.svg";
import Facebook from "../../assets/Facebookicon.svg";
import LinkedIn from "../../assets/LinkedinIcon.svg";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../router/Paths";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useGetProducts } from "../../hook/useGetProducts";
import { setActiveHeaderTab } from "../../reducers/headerReducer";
import { setSelectedSection } from "../../reducers/pageReducer";

function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getTabProducts } = useGetProducts();
  const { active } = useSelector((state: RootState) => state.page);

  const handleSelect = async (selected?: string) => {
    switch (selected) {
      case aboutHomeTab:
        dispatch(setActiveHeaderTab(null));
        dispatch(setSelectedSection(null));
        if (active === Paths.home) {
          window.scrollTo(0, 0);
        } else {
          navigate(Paths.home);
        }
        break;
      case aboutProductsTab:
        if (active === Paths.shop) {
          window.scrollTo(0, 0);
        } else {
          navigate(Paths.shop);
        }
        break;
      case aboutFaqsTab:
        navigate(Paths.home);
        dispatch(setActiveHeaderTab(selected as HeadertabType));
        dispatch(setSelectedSection(selected as HeadertabType));
        break;
      default:
        const categoryArray = Object.values(CategoryTabs);
        const selectedTab = categoryArray.find(
          (item) => item.name === selected
        );
        if (selectedTab) {
          await getTabProducts(selectedTab.name);
          dispatch(setActiveHeaderTab(selectedTab.name));
          navigate(`${Paths.shop}/${selected}`);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 1000);
        } else {
          navigate(Paths.home);
          dispatch(setActiveHeaderTab(null));
          dispatch(setSelectedSection(null));
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 200);
        }
        break;
    }
  };

  const {
    logoDescription,
    aboutFaqsTab,
    aboutHomeTab,
    aboutProductsTab,
    aboutTab,
    contactUsTab,
    shopTab,
  } = AppCopy.footer;

  return (
    <div className={ClassName.container}>
      <Section classname={ClassName.content}>
        <div className={ClassName.footerContent}>
          <div className={ClassName.footerContentColumn}>
            <img
              src={Logo}
              alt="logo"
              className={ClassName.footerLogo}
              onClick={() => handleSelect()}
            />
            <div className={ClassName.footerLogoDescription}>
              {logoDescription}
            </div>
          </div>
          <div className={ClassName.footerContentColumn}>
            <span className={ClassName.footerTabsTitle}>{aboutTab}</span>
            <span
              className={ClassName.footerTab}
              onClick={() => handleSelect(aboutHomeTab)}
            >
              {aboutHomeTab}
            </span>
            <span
              className={ClassName.footerTab}
              onClick={() => handleSelect(aboutProductsTab)}
            >
              {aboutProductsTab}
            </span>
            <span
              className={ClassName.footerTab}
              onClick={() => handleSelect(aboutFaqsTab)}
            >
              {aboutFaqsTab}
            </span>
          </div>
          <div
            className={`${ClassName.footerContentColumn} ${ClassName.footerContentColumnShop}`}
          >
            <span className={ClassName.footerTabsTitle}>{shopTab}</span>
            {Object.values(CategoryTabs).map(
              (category: CategoryTabInterface, index: number) => (
                <span
                  key={index}
                  className={ClassName.footerTab}
                  onClick={() => handleSelect(category.name)}
                >
                  {category.displayName}
                </span>
              )
            )}
          </div>
          <div className={ClassName.footerContentColumn}>
            <span className={ClassName.footerTabsTitle}>{contactUsTab}</span>
            <span className={ClassName.footerTabContact}>
              {Contact.phoneNumber}
            </span>
            <span className={ClassName.footerTabContact}>{Contact.email}</span>
            <div className={ClassName.footerSocialsContainer}>
              <a href={Contact.instagramLink} target="_blank" rel="noreferrer">
                <img
                  src={Instagram}
                  alt="insta"
                  className={ClassName.footerSocial}
                />
              </a>
              <a href={Contact.facebookLink} target="_blank" rel="noreferrer">
                <img
                  src={Facebook}
                  alt="insta"
                  className={ClassName.footerSocial}
                />
              </a>
              <a href={Contact.linkedInLink} target="_blank" rel="noreferrer">
                <img
                  src={LinkedIn}
                  alt="insta"
                  className={ClassName.footerSocial}
                />
              </a>
            </div>
          </div>
        </div>
        <div className={ClassName.footerBorder}></div>
      </Section>
    </div>
  );
}

export default Footer;

const ClassName = {
  container:
    "w-full min-height-40vh flex items-center justify-center gradient-footer-background font-secondary",
  content: "w-full flex flex-col items-center mt-52",
  footerBorder: "w-10/12 sm:w-8/12 h-px bg-secondary mt-14 mb-20",
  footerContent:
    "w-10/12 flex flex-col sm:flex-row items-center sm:items-start justify-between",
  footerContentColumn: "flex flex-col items-center sm:items-start mb-4 sm:mb",
  footerContentColumnShop: "hidden md:flex flex-col",
  footerLogo: "w-20 md:w-24 object-contain cursor-pointer",
  footerLogoDescription:
    "w-48 lg:w-60 xl:w-80 text-xs lg:text-sm text-quaternary mt-4 sm:mt-8 text-center sm:text-start",
  footerTabsTitle: "font-medium text-sm lg:text-base mb-4 text-black",
  footerTab:
    "text-xs lg:text-sm  text-quaternary hover:text-black cursor-pointer mb-2",
  footerTabContact: "text-xs lg:text-sm  text-quaternary mb-2",
  footerSocialsContainer: "flex flex-row items-center mt-4",
  footerSocial: "w-5 h-5 object-contain mr-6 cursor-pointer",
};
