import React, { useState } from "react";
import Section from "../components/common/Section";
import { ProductHeroSectionProps } from "../declarations/sections";
import ChevronLeft from "../assets/ChevronLeftPrimary.svg";
import ChevronRight from "../assets/ChevronRightWhite.svg";
import AppCopy from "../copy/AppCopy";
import { getPriceWithCurrency } from "../functions/currency";
import { ProductSizeType } from "../declarations/productService";
import useCart from "../hook/useCart";

function ProductHeroSection({ product }: ProductHeroSectionProps) {
  const { addToCart } = useCart();

  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [selectedSize, setSelectedSize] = useState<number | undefined>(0);
  const [selectedColor, setSelectedColor] = useState<string>();

  const handleSelectImage = (index: number) => {
    setSelectedImage(index);
  };

  const OnNext = () => {
    if (!product?.images) return;
    if (selectedImage === product?.images?.slice(0, 4)?.length - 1) {
      setSelectedImage(0);
      return;
    }
    setSelectedImage(selectedImage + 1);
  };

  const OnPrevious = () => {
    if (!product?.images) return;
    if (selectedImage === 0) {
      setSelectedImage(product?.images?.slice(0, 4)?.length - 1);
      return;
    }
    setSelectedImage(selectedImage - 1);
  };

  const OnSelectSize = (index: number) => {
    setSelectedSize(index);
  };

  const handleAddToCart = () => {
    if (!product) return;
    addToCart({
      product: product,
      price:
        product.sizes && selectedSize
          ? product.sizes[selectedSize].price
          : product.price,
      productName: product.name,
      quantity: 1,
      selectedSize:
        product.sizes && selectedSize
          ? product.sizes[selectedSize].sizeName
          : "",
    });
  };

  const { selectSizeTitleText, addToCartButtonText, freeDeliveryTagText } =
    AppCopy.product;

  return (
    <Section
      parentClassname={ClassName.parentContainer}
      classname={ClassName.container}
    >
      <div className={ClassName.content}>
        <div className={`${ClassName.productName} ${ClassName.mobile}`}>
          {product?.name}
        </div>
        <div className={ClassName.contentLeft}>
          <div className={ClassName.arrowsContainer}>
            <div
              className={`${ClassName.arrowContainer} ${ClassName.rightArrowContainer}`}
              onClick={OnNext}
            >
              <img src={ChevronRight} alt="" className={ClassName.arrow} />
            </div>
            <div
              className={`${ClassName.arrowContainer} ${ClassName.leftArrowContainer}`}
              onClick={OnPrevious}
            >
              <img src={ChevronLeft} alt="" className={ClassName.arrow} />
            </div>
          </div>
          <div className={ClassName.mainImageContainer}>
            <div className={ClassName.mainImageContent}>
              {product?.images && (
                <img
                  src={product?.images[selectedImage]}
                  alt="product"
                  className={ClassName.mainImage}
                />
              )}
            </div>
          </div>
          <div className={ClassName.imagesContainer}>
            {product?.images?.map((image: any, index: number) => (
              <div
                key={index}
                className={`${ClassName.miniImageContainer} ${
                  selectedImage === index && ClassName.imageContainerActive
                }`}
                onClick={() => handleSelectImage(index)}
              >
                <img
                  src={image}
                  alt="product"
                  className={ClassName.miniImage}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={ClassName.contentRight}>
          <div className={`${ClassName.productName} ${ClassName.desktop}`}>
            {product?.name}
          </div>
          {product?.availableSizes ? (
            <>
              {/* <div className={ClassName.selectSizeTitle}>
                {selectSizeTitleText}
              </div> */}
              <div className={ClassName.sizesContainer}>
                {product.sizes?.map((size: ProductSizeType, index) => (
                  <div
                    key={index}
                    className={`${ClassName.sizeContainer} ${
                      selectedSize === index && ClassName.selectedSize
                    }`}
                    onClick={() => OnSelectSize(index)}
                  >
                    {size.sizeName}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: `${product?.description?.slice(0, 300) as string} ${
                  product?.description.length &&
                  product?.description.length > 300 &&
                  "..."
                }`,
              }}
            ></p>
          )}
          <div className={ClassName.price}>
            {getPriceWithCurrency(
              (product?.sizes &&
                selectedSize &&
                product?.sizes[selectedSize].price) ||
                product?.price
            )}
          </div>
          <button
            className={ClassName.addToCartButton}
            onClick={() => handleAddToCart()}
          >
            {addToCartButtonText}
          </button>
          <div className={ClassName.freeDeliveryText}>
            {freeDeliveryTagText}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ProductHeroSection;

const ClassName = {
  parentContainer:
    "min-height-100vh w-full bg-tertiary flex items-center justify-center",
  container: "flex flex-row items-center py-20 font-secondary",
  content: "flex w-full flex-col lg:flex-row items-start lg:items-center",
  contentLeft:
    "flex w-full lg:w-7/12 relative flex-col items-center justify-between height-80vh",
  imagesContainer:
    "flex flex-row md:flex-wrap overflow-scroll md:overflow-hidden items-center justify-start sm:justify-center w-full",
  miniImageContainer:
    " w-20 h-20 sm:w-28 sm:h-28 rounded-lg bg-secondaryWhite shrink-0 flex items-center justify-center my-4 mx-2 md:mx-4 cursor-pointer",
  miniImage: "w-16 h-16 object-contain",
  imageContainerActive: "border border-primary",
  mainImageContainer: "flex flex-col flex-1 items-center justify-center",
  mainImageContent: "w-full h-40vh sm:h-60vh flex items-center justify-center",
  mainImage:
    " w-80percent h-90percent sm:w-70percent sm:h-80percent object-contain",
  arrowsContainer: "absolute top-6 left-6 flex flex-col items-center",
  arrowContainer:
    "w-8 h-8 sm:h-11 sm:w-11 rounded-full my-2 cursor-pointer drop-shadow-md shadow-primary flex flex-col items-center justify-center",
  rightArrowContainer: "bg-primary",
  leftArrowContainer: "bg-white",
  arrow: "h-2.5 w-2.5 object-contain",
  contentRight: "flex flex-1 flex-col mt-10 lg:mt-0",
  productName: "font-primary text-3xl sm:text-5xl lg:text-6xl mb-6",
  selectSizeTitle: "text-sm md:text-base text-quaternary font-semibold mb-6",
  sizesContainer: "flex flex-row flex-wrap lg:max-width-60percent",
  sizeContainer:
    "h-8 md:h-10 px-4 mr-4 md:mr-6 my-1 md:my-2 text-primary text-xs sm:text-sm border border-primary cursor-pointer flex items-center justify-center",
  selectedSize: "bg-primary text-white",
  price: "font-primary text-xl md:text-2xl text-quaternary my-4",
  addToCartButton: "button-primary mt-6",
  freeDeliveryText: "text-base text-primary font-medium mt-6",
  mobile: "flex md:hidden",
  desktop: "hidden  md:flex",
};
