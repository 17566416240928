import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ShopPage from "../pages/ShopPage";
import { Paths } from "./Paths";
import CartPage from "../pages/CartPage";
import ProductPage from "../pages/ProductPage";
import useLoadCache from "../hook/useLoadCache";
import LoginPage from "../pages/LoginPage";
import DashboardHomePage from "../pages/DashboardHomePage";
import { useCheckUser } from "../hook/useCheckUser";
import SideBar from "../components/common/SideBar";
import DashboardOrdersPage from "../pages/DashboardOrdersPage";
import DashboardProductsPage from "../pages/DashboardProductsPage";
import ProgressBar from "../components/common/ProgressBar";
import DashboardAddProductPage from "../pages/DashboardAddProductPage";
import DashboardUpdateProductPage from "../pages/DashboardUpdateProductPage";

function AppRouter() {
  useLoadCache();

  return (
    <Router>
      <Routes>
        <Route path={Paths.home} Component={HomePage} />
        <Route path={Paths.shop} Component={ShopPage} />
        <Route path={Paths.category} Component={ShopPage} />
        <Route path={Paths.cart} Component={CartPage} />
        <Route path={Paths.product} Component={ProductPage} />
        <Route path={Paths.productPage} Component={ProductPage} />
        <Route path={Paths.dashboardLogin} Component={LoginPage} />
        <Route path={Paths.admin} Component={AdminRoutes} />
      </Routes>
    </Router>
  );
}

function AdminRoutes() {
  useCheckUser();
  return (
    <div className={ClassName.adminContainer}>
      <ProgressBar />
      <SideBar />
      <Routes>
        <Route path={Paths.dashboardHome} Component={DashboardHomePage} />
        <Route path={Paths.dashboardHome1} Component={DashboardHomePage} />
        <Route path={Paths.dashboardOrders} Component={DashboardOrdersPage} />
        <Route
          path={Paths.dashboardProducts}
          Component={DashboardProductsPage}
        />
        <Route
          path={Paths.dashboardAddProduct}
          Component={DashboardAddProductPage}
        />
        <Route
          path={Paths.dashboardUpdateProductPage}
          Component={DashboardUpdateProductPage}
        />
      </Routes>
    </div>
  );
}

export default AppRouter;

const ClassName = {
  adminContainer: "flex flex-row ",
};
